import { useState } from 'react';
import { Theme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ccsTheme, klvTheme } from './theme/theme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import FormRenderer from 'component/molecules/Formrenderer/FormRenderer';
import { useKeycloak } from 'context/Keycloak/Keycloak';
import { SnackBarProvider } from 'context/Snackbar/Snackbar';
import Layout from 'component/pages/Layout/Layout';
import { useTranslation } from 'react-i18next';
import Login from 'component/pages/Login/Login';
import { LoaderProvider } from 'context/Loader/Loader';
import { Action } from 'component/pages/Action/Action';
import config from 'config/config';
import PartyDetails from 'component/pages/Party/PartyDetails';
import Underconstruction from 'component/pages/UnderConstruction/Underconstruction';
import SettingsPage from 'component/pages/Settings/SettingsPage';
import FormBuilderRenderer from 'component/molecules/Formrenderer/FormBuilderRenderer';

const App = () => {
  const { initialized, authenticated } = useKeycloak();
  const { i18n } = useTranslation<string>();

  const [theme, setTheme] = useState<Theme>(ccsTheme);
  const [themeVal, setThemeVal] = useState<string>('CCS Theme');

  const handleTheme = (event: any) => {
    const root: any = document.querySelector(':root');
    if (event.target.value === 'CCS Theme') {
      setTheme(ccsTheme);
      root?.style?.setProperty('--theme-color', '#0046ff');
    }
    if (event.target.value === 'KVL Theme') {
      setTheme(klvTheme);
      root?.style?.setProperty('--theme-color', '#31912e');
    }
    setThemeVal(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <CssBaseline></CssBaseline>
        <Router basename={config.basename}>
          {initialized() && !authenticated() && <Login theme={themeVal} />}
          {authenticated() && (
            <LoaderProvider>
              <SnackBarProvider>
                <Layout handleTheme={handleTheme} theme={themeVal}>
                  <Routes>
                    <Route index path='/' element={<Action />} />
                    <Route path='action' element={<Action />} />
                    <Route path='insurances' element={<Underconstruction />} />
                    <Route path='settings' element={<SettingsPage theme={themeVal} handleTheme={handleTheme} />} />
                    <Route path='settings/:key' element={<SettingsPage theme={themeVal} handleTheme={handleTheme} />} />
                    <Route path='offer' element={<Underconstruction />} />
                    <Route path='offers' element={<Underconstruction />} />
                    <Route path='tasks' element={<Underconstruction />} />
                    <Route path='claim' element={<Underconstruction />} />
                    <Route path='forms' element={<FormBuilderRenderer />} />
                    <Route path='party/partydetails/:partyNumber/:module' element={<PartyDetails />} />
                    <Route path='formrenderer/:formsrc/:partynumber' element={<FormRenderer />} />
                    <Route path='formrenderer/:formsrc' element={<FormRenderer />} />
                  </Routes>
                </Layout>
              </SnackBarProvider>
            </LoaderProvider>
          )}
        </Router>
      </I18nextProvider>
    </ThemeProvider>
  );
};

export default App;
