import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import config from 'config/config';
import { useSnackBar } from 'context/Snackbar/Snackbar';

//===============================================
// private variables
//===============================================
// To prevent keycloak.logout to show the logout screen the
// id_token_hint param must be set with the original idToken.
// The updateToken clear's it on error so it must be stored.
let original_idToken: string | undefined;

const keycloak = new Keycloak(config.keycloak);

const defaultOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
  pkceMethod: 'S256'
};

const setGlobalLogout = (value: boolean) => {
  localStorage.setItem('KeyCloakLogout', value.toString());
};

const isGlobalLogout = () => {
  return localStorage.getItem('KeyCloakLogout') === 'true';
};

//===============================================
// interfaces
//===============================================

interface IKeycloakContext {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  authenticated: () => boolean;
  initialized: () => boolean;
}

interface IKeycloakProviderProps {
  children: ReactNode | any;
}

//===============================================
// context
//===============================================

const KeycloakContext = createContext<IKeycloakContext>({
  login: () => Promise.reject(),
  logout: () => Promise.reject(),
  authenticated: () => false,
  initialized: () => false
});

//===============================================
// provider
//===============================================

const KeycloakProvider = (props: IKeycloakProviderProps) => {
  const { children } = props;
  const [initialized, setInitialized] = useState<boolean>(false);
  const { showSnackBar } = useSnackBar();

  useEffect(() => {
    const initialize = () => {
      keycloak
        .init({ ...defaultOptions })
        .then(() => {
          setInitialized(true);
        })
        .catch((err) => {
          showSnackBar(err.message, 'error');
          //throw new Error(err);
        });
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    login: () => {
      setGlobalLogout(false);
      return keycloak.login();
    },
    logout: () => {
      setGlobalLogout(true);
      setInitialized(false);
      keycloak.authenticated = false;
      return keycloak.logout();
    },
    authenticated: () => {
      return !isRefreshTokenExpired() && !!keycloak.authenticated ? !isGlobalLogout() : false;
    },
    initialized: () => initialized
  };

  return <KeycloakContext.Provider value={value}>{children}</KeycloakContext.Provider>;
};

//===============================================
// hook
//===============================================

const useKeycloak = () => {
  const context = useContext(KeycloakContext);

  if (!context) {
    throw new Error('useKeycloak must be used within an KeycloakProvider');
  }

  return context;
};

//===============================================
// public variables
//===============================================

const getToken = () => {
  return keycloak.token;
};

const isRefreshTokenExpired = () => {
  const refreshToken = keycloak.refreshTokenParsed;

  if (refreshToken && refreshToken.exp) {
    // convert to milliseconds since epoch
    const expirationTime = refreshToken.exp * 1000;
    const currentTime = Date.now();

    return expirationTime < currentTime;
  }

  return false;
};

const getTokenParsed = () => {
  return keycloak.tokenParsed;
};

const logout = () => {
  if (!keycloak.idToken) {
    keycloak.idToken = original_idToken;
  }

  return keycloak.logout({ redirectUri: config.redirect_url });
};

const updateToken = (minValidity: number = 5) => {
  original_idToken = keycloak.idToken;
  return keycloak.updateToken(minValidity);
};

const getTokenProperty = (name: string) => {
  const tokenParsed = keycloak.tokenParsed;

  if (tokenParsed && tokenParsed[name]) {
    return tokenParsed[name] as string;
  }

  return '';
};

const isTokenExpired = (minValidity: number = 0): boolean => {
  return keycloak.isTokenExpired(minValidity);
};

const getScope = (): string => {
  const tokenParsed = keycloak.tokenParsed;
  return tokenParsed ? tokenParsed['scope'] : '';
};

export {
  KeycloakProvider,
  useKeycloak,
  getToken,
  getTokenParsed,
  getTokenProperty,
  getScope,
  updateToken,
  logout,
  isTokenExpired,
  isRefreshTokenExpired
};
