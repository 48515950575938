import EntityService from 'entities/EntityService';
import { IBilling } from './IBilling';

type BillingSearchFilter = 'PartyNumber';

//==============================================================
// Billing EntityService
//==============================================================

export class BillingService extends EntityService<IBilling, BillingSearchFilter> {
  protected flat(data: any) {
    return data?.Billings ?? [];
  }

  protected getId(item: IBilling) {
    return item.BillingNumber;
  }

  protected getPath() {
    return '/connect/v3/billings';
  }
}

export type { BillingSearchFilter };
export default BillingService;
