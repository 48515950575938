import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
//import InputBase from '@mui/material/InputBase';
export const DatePickerField = styled(DatePicker)(() => ({
  backgroundColor: '#fff',
  position: 'relative',
  '& .MuiOutlinedInput-root input.MuiInputBase-inputAdornedEnd': { paddingLeft: '40px' },
  '& .MuiPickersArrowSwitcher-root': {
    width: 0
  },
  '& .MuiPickersCalendarHeader-labelContainer': {
    margin: 'auto'
  },
  '& .MuiIconButton-edgeEnd': {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0
  },
  '& .MuiIconButton-edgeStart': {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0
  },
  '& .arrowStyle': {
    color: '#000',
    fontSize: '2rem'
  }
}));
