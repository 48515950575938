import { useEffect, useState } from 'react';
import FormioService from 'entities/Formio/FormioService';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import { IFormInfo } from '@ccs-dip/common/types/formio-types';

export interface FormioRow {
  title: string;
  key: string;
  // Add any other properties as needed
}

const useFormioData = () => {
  const [rows, setRows] = useState<IFormInfo[]>([]);
  const service = new FormioService();
  const { showSnackBar } = useSnackBar();

  const fetchFormio = () => {
    Promise.resolve(service.getAllForm())
      .then((response: IFormInfo[]) => {
        setRows([...response]);
      })
      .catch(() => {
        showSnackBar('Error fetching data', 'error');
      });
  };

  useEffect(() => {
    fetchFormio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFormio = (title: string) => {
    Promise.resolve(service.delete(title))
      .then(() => {
        showSnackBar('Deleted Successfully', 'success');
        fetchFormio();
      })
      .catch(() => {
        showSnackBar('Unable to delete', 'error');
      });
  };

  return { rows, deleteFormio, fetchFormio };
};

export default useFormioData;
