
import { useTheme } from '@mui/material/styles';
function IconInsuranceCar2(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "38"}
            height={_props.height ? _props.height : "38"}
            viewBox="0 0 38 38"
        >
            <circle
                cx="19"
                cy="19"
                r="19"
                fill="rgba(0,70,255,.05)"
                data-name="Ellipse 467"
            ></circle>
            <g transform="translate(9 8.551)">
                <path fill="none" d="M0 0h20v20H0z" data-name="Path 3658"></path>
                <path
                    fill={_props.color ? _props.color : theme.palette.secondary.main}
                    d="M15.767 5.009a1.245 1.245 0 00-1.184-.842H5.417a1.253 1.253 0 00-1.183.842L2.5 10v6.667a.836.836 0 00.833.833h.833A.836.836 0 005 16.667v-.833h10v.833a.836.836 0 00.833.833h.833a.836.836 0 00.834-.833V10zm-10.059.825h8.575l.9 2.592H4.808zm10.125 8.333H4.167V10h11.666z"
                    data-name="Path 3659"
                ></path>
                <circle
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    fill={_props.color ? _props.color : theme.palette.secondary.main}
                    data-name="Ellipse 488"
                    transform="translate(5 10.833)"
                ></circle>
                <circle
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    fill={_props.color ? _props.color : theme.palette.secondary.main}
                    data-name="Ellipse 489"
                    transform="translate(12.5 10.833)"
                ></circle>
            </g>
        </svg>
    );
}

export default IconInsuranceCar2;
