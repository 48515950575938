import Button from '@mui/material/Button';
import { styled } from '@mui/material';

export const StyledButton = styled(Button)(({ theme, variant }) => ({
  backgroundColor: variant === 'contained' ? theme.palette.secondary.main : 'transparent',
  border: variant === 'outlined' ? `1px solid ${theme.palette.secondary.main}` : 'none',
  color: variant === 'outlined' ? theme.palette.secondary.main : theme.palette.common.white,
  '&:hover': {
    backgroundColor: variant === 'contained' ? theme.palette.primary.main : 'transparent'
  }
}));
