import { useTheme } from '@mui/material/styles';
function IconSlideLeft(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            data-name="Layer 1"
            viewBox="0 0 100 125"
            width={_props.width ? _props.width : "38"}
            height={_props.height ? _props.height : "38"}
        >
            <path fill={_props.color ? _props.color : theme.palette.primary.main} d="M52.24 38L48 33.76 33.88 47.88a3 3 0 000 4.24L48 66.24 52.24 62l-9-9H67v-6H43.24z"></path>
            <path fill={_props.color ? _props.color : theme.palette.primary.main} d="M19 23H25V77H19z"></path>
        </svg>
    );
}

export default IconSlideLeft;
