import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
  GridRowModes,
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridValueGetterParams,
  GridValueSetterParams,
  GridRowParams,
  GridRowModesModel
} from '@mui/x-data-grid-pro';

import { LanguageCode } from 'entities/Translation/TranslationService';
import { TranslationRow } from './TranslationRowMap';
import useTranslationData from './useTranslationData';
import useTranslationEvents from './useTranslationEvents';
import { useTranslation } from 'react-i18next';
import CustomDataGridToolbar, { DataGridProSx } from 'utils/CustomDataGridToolbar';
import {
  Box,
  Button
} from '@mui/material';
import { formButtonWrap, toolBar, toolbarWrap } from 'component/molecules/Formrenderer/FormGridStyledComponent';
import UploadFormJson from 'component/molecules/Formrenderer/FormJsonUpload';
import { FormButtonProps } from 'component/molecules/Formrenderer/IFormDataGrid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { generateRandomId } from 'utils/Utils';
import ImportDialog from './TranslationImportDialog';

//===============================================
// local variables
//===============================================

const translationValueGetter = (params: GridValueGetterParams<TranslationRow>) => {
  const row = params.row;
  const lang = params.field as LanguageCode;
  const data = row[lang];
  return hasCustomValue(row, lang) ? data?.['custom'] : data?.['default'];
};

const translationValueSetter = (params: GridValueSetterParams<TranslationRow>, lang: LanguageCode) => {
  const row = params.row;
  const value = params.value;

  if (row) {
    const data = row[lang] ?? (row[lang] = {});
    const isDefault = value === data['default'];

    if (isDefault) {
      delete data['custom'];
    } else {
      data['custom'] = value;
    }
  }

  return { ...row };
};

const typeValueGetter = (params: GridValueGetterParams<TranslationRow>, rowModesModel: GridRowModesModel) => {
  const row = params.row;
  const isEdit = rowModesModel[row.key]?.mode === GridRowModes.Edit;
  const isCustom = hasCustomValue(row, 'en', 'nl');
  return isEdit ? 'Editing' : isCustom ? 'Custom' : 'Default';
};

const hasCustomValue = (row: TranslationRow, ...languages: LanguageCode[]) => {
  return languages.some((lang) => {
    const data = row[lang] ?? {};
    return data['custom'] !== undefined;
  });
};

//===============================================
// Component render function
//===============================================

export default function TranslationDataGrid() {
  const { rows, api } = useTranslationData();
  const { rowModesModel, events,open,overrideOption } = useTranslationEvents(rows, api);
 
  const { t } = useTranslation();

  const GridButton: React.FC<FormButtonProps> = ({ onClick, icon, label }) => (
    // eslint-disable-next-line react/jsx-no-undef
    <Button variant='contained' sx={formButtonWrap} onClick={onClick}>
      {icon}
      {label}
    </Button>
  );
  
  const addNewRow = () => {
    const id = generateRandomId();
    api.addRow(id);
    const onAdd = events.onAdder(id);
    onAdd();
  };

  const columns: GridColDef[] = [
    { field: 'key',headerName: t('Key'), flex: 3 },
    { field: 'type', headerName: t('Type'), flex: 1, valueGetter: (params) => typeValueGetter(params, rowModesModel) },
    {
      field: 'en',
      headerName: t('English'),
      flex: 4,
      editable: true,
      valueGetter: translationValueGetter,
      valueSetter: (params) => translationValueSetter(params, 'en')
    },
    {
      field: 'nl',
      headerName: t('Dutch'),
      flex: 4,
      editable: true,
      valueGetter: translationValueGetter,
      valueSetter: (params) => translationValueSetter(params, 'nl')
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      flex: 2,
      cellClassName: 'actions',
      getActions: (params: GridRowParams<TranslationRow>) => {
        const { id, row } = params;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const isDefault = !hasCustomValue(row, 'en', 'nl');

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={events.onSave(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={events.onCancel(id)}
              color='inherit'
            />

          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={events.onEdit(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={events.onDelete(id)}
            color='inherit'
            disabled={isDefault}
          />
        ];
      }
    }
  ];

  return (
    <>
      <DataGridPro
        sx={DataGridProSx}
        rows={rows}
        columns={columns}
        editMode='row'
        getRowId={(row: any) => row.key || row.id}
        rowModesModel={rowModesModel}
        columnHeaderHeight={36}
        onRowModesModelChange={events.onRowModesModelChange}
        onRowEditStop={events.onRowEditStop}
        processRowUpdate={events.processRowUpdate}
        disableColumnPinning
        pagination
        initialState={{
          sorting: {
            sortModel: [{ field: 'key', sort: 'asc' }]
          },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[
          10,
          20,
          40
        ]}
        slots={{ toolbar: CustomDataGridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            title: t('translationdatagrid')
          }
        }}
      />
      <Box sx={toolBar}>
        <Box sx={toolbarWrap}>
          <UploadFormJson handleFileUpload={events.onFileUpload} />
          <GridButton
            onClick={() => events.onExportAsJson()}
            icon={<CloudDownloadIcon sx={{ fontSize: '20px', mr: 1 }}></CloudDownloadIcon>}
            label={t('export')}
          />
          <GridButton
            onClick={() => {
              addNewRow();
            }}
            icon={<AddCircleOutlineIcon sx={{ fontSize: '20px', mr: 1 }}></AddCircleOutlineIcon>}
            label={'Add Record'}
          />
        </Box>
        <ImportDialog
        open={open}
        onClose={events.onDialogClose}
        overrideOption={overrideOption}
        onOverrideChange={events.onOverrideChange}
      />
      </Box>
    </>
  );
}
