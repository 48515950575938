import { useTheme } from '@mui/material/styles';

function IconShow(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3656"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M12 6.5a9.77 9.77 0 018.82 5.5 9.822 9.822 0 01-17.64 0A9.77 9.77 0 0112 6.5m0-2A11.827 11.827 0 001 12a11.817 11.817 0 0022 0 11.827 11.827 0 00-11-7.5zm0 5A2.5 2.5 0 119.5 12 2.5 2.5 0 0112 9.5m0-2a4.5 4.5 0 104.5 4.5A4.507 4.507 0 0012 7.5z"
                data-name="Path 3657"
            ></path>
        </svg>
    );
}

export default IconShow;