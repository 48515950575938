import { createTheme } from '@mui/material/styles';

const theme = {
  typography: {
    fontFamily: 'Open Sans'
  },

  shape: {
    borderRadius: 8
  },

  palette: {
    primary: {
      main: '#00005a',
      contrastText: '#fff'
    },
    secondary: {
      main: '#0046ff',
      contrastText: '#rgba(0, 70, 255, 0.05)'
    }
  }
};

const klv_theme = {
  typography: {
    fontFamily: 'Open Sans'
  },

  shape: {
    borderRadius: 8
  },

  palette: {
    primary: {
      main: '#00005a'
    },
    secondary: {
      main: '#31912e'
    }
  }
};

export const ccsTheme = createTheme(theme);
export const klvTheme = createTheme(klv_theme);
