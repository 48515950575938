import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import SourceIcon from '@mui/icons-material/Source';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const FormGridIcons = {
  DriveFileRenameOutlineOutlinedIcon,
  DescriptionOutlinedIcon,
  DeleteOutlineIcon,
  VideoLabelIcon,
  AddCircleOutlineIcon,
  AddToPhotosOutlinedIcon,
  LaunchIcon,
  FileDownloadIcon,
  SourceIcon
};
export default FormGridIcons;
