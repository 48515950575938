import { useTheme } from '@mui/material/styles';

function IconEmptyDoc(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3721"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.secondary.main}
                d="M14 2H6a2 2 0 00-1.99 2L4 20a2 2 0 001.99 2H18a2.006 2.006 0 002-2V8zM6 20V4h7v5h5v11z"
                data-name="Path 3722"
            ></path>
        </svg>
    );
}

export default IconEmptyDoc;