import { Dropdownpicker } from 'component/organisms/Dropdownpicker/Dropdownpicker';
import Box from '@mui/material/Box';
interface settingsProps {
  handleTheme?: (theme: string) => void;
  theme?: string;
}

export const Settings = (_props: settingsProps) => {
  return (
    <Box>
      <Box>
        <Dropdownpicker
          handleChange={_props?.handleTheme}
          value={_props.theme}
          customMenuOptions={[
            { key: 'ccsTheme', value: 'CCS Theme' },
            { key: 'kvlTheme', value: 'KVL Theme' }
          ]}></Dropdownpicker>
      </Box>
    </Box>
  );
};
