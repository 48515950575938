import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Box,Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ActionMenuItem } from './IButtonMenu';
import { actionMenu } from './ButtonMenuOptions';
import { ActionMenu } from './ButtonStyled';
import { useTranslation } from 'react-i18next';


export default function AddOptions() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const initialOption = localStorage.getItem('selectedOption') 
    ? JSON.parse(localStorage.getItem('selectedOption') as string) 
    : actionMenu[1];
  const [option, setOption] = React.useState<ActionMenuItem>(initialOption);
  const {t}=useTranslation();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (itm: ActionMenuItem) => {
    setAnchorEl(null);
    setOption(itm);
    localStorage.setItem('selectedOption', JSON.stringify(itm));
    navigate(`/formrenderer/${itm.url}`);
  };

  const handleClick = () => {
    navigate(`/formrenderer/${option?.url}`);
  };

  return (
    <div>
      <Button
        id="ActionButtonMenu"
        aria-controls={open ? 'Action-Button-Menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        sx={{ py: '6px', width: 'max-content', bgcolor:(theme)=>theme.palette.secondary.main }}
        onClick={handleClick}
        endIcon={<Box onClick={handleMenuClick}><KeyboardArrowDownIcon /></Box>}
      >
        <AddCircleIcon sx={{ fontSize: '20px', mr: 1 }}></AddCircleIcon>
        <Typography sx={{ fontSize: '13px' }}>{t(option.key)}</Typography>
      </Button>
      <ActionMenu
        id="Action-Button-Menu"
        MenuListProps={{
          'aria-labelledby': 'ActionButtonMenu',
        }}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => { if(actionMenu[0])handleClose(actionMenu[0]); }}
      >
        {actionMenu.map((itm) => (
          <MenuItem key={itm.key} onClick={() => { handleClose(itm); }} disableRipple>
            <Box sx={{ mr: 1 }}> <AddCircleIcon sx={{fontSize:'20px',color:'#fff'}}/></Box>
            <Typography sx={{ fontSize: '13px',textTransform: 'uppercase'}}>{t(itm.key)}</Typography>
          </MenuItem>
        ))}
      </ActionMenu>
    </div>
  );
}
