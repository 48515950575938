import EntityService from 'entities/EntityService';
import { IArchive } from './IArchive';

type ArchiveSearchFilter = 'PartyNumber';

//==============================================================
// Archive EntityService
//==============================================================

export class ArchiveService extends EntityService<IArchive, ArchiveSearchFilter> {
  protected flat(data: any) {
    return data?.Archives ?? [];
  }

  protected getId(item: IArchive) {
    return item.ArchiveNumber;
  }

  protected getPath() {
    return '/document/v4/archives';
  }
}

export type { ArchiveSearchFilter };
export default ArchiveService;
