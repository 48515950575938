export const dropDownStyle = {
  '&.MuiOutlinedInput-root': { marginTop: 0 },
  backgroundColor: '#fff',
  '& .selectStyle': {
    '& .MuiSelect-select.MuiInputBase-input': {
      color: '#ccc'
    }
  },
  '& .selectInputPropsStyle': {
    color: '#00005a'
  }
};
