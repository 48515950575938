
import { useTheme } from '@mui/material/styles';
function IconCloudTick(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3711"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M19.35 10.04a7.492 7.492 0 00-14-2A6 6 0 006 20h13a4.986 4.986 0 00.35-9.96zM19 18H6a4 4 0 01-.44-7.97l1.07-.11.5-.95a5.487 5.487 0 0110.26 1.46l.3 1.5 1.53.11A2.983 2.983 0 0119 18zm-9-3.82l-2.09-2.09L6.5 13.5 10 17l6.01-6.01-1.41-1.41z"
                data-name="Path 3712"
            ></path>
        </svg>
    );
}

export default IconCloudTick;