import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { ArrowTooltipStyles } from './ToolTipStyles';

const ArrowTooltip = styled((props: TooltipProps) => (
  <Tooltip id='ArrowTooltip' sx={ArrowTooltipStyles} {...props} arrow className='ArrowTooltipStyles' />
))(() => ({
  ArrowTooltipStyles
}));

export default ArrowTooltip;
