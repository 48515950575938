import Box from '@mui/material/Box';
import CountryCode from 'component/atoms/CountryCode/CountryCode';
import Phonenumber from 'component/atoms/Phonenumber/Phonenumber';
import { useTranslation } from 'react-i18next';

export default function InternationalPhone() {
  const { t } = useTranslation<string>();

  return (
    <Box sx={{ display: 'flex' }}>
      <CountryCode label={t('countryCode')}></CountryCode>
      <Phonenumber />
    </Box>
  );
}
