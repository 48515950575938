import { useEffect, useState } from 'react';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import { OpenApiService } from 'entities/OpenApi/OpenApiService';
import { SwaggerInfo } from '@ccs-dip/common/SwaggerFileInfo';

export interface FormioRow {
  title: string;
  key: string;
  // Add any other properties as needed
}

const useSwaggerData = () => {
  const [rows, setRows] = useState<SwaggerInfo[]>([]);
  const service = new OpenApiService();
  const { showSnackBar } = useSnackBar();

  const fetchSwagger = () => {
    Promise.resolve(service.getAllSwaggers())
      .then((response: any) => {
        setRows([...response]);
      })
      .catch(() => {
        showSnackBar('Error fetching data', 'error');
      });
  };

  useEffect(() => {
    fetchSwagger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSwagger = (fileName: string) => {
    Promise.resolve(service.deleteSwagger(fileName))
      .then(() => {
        showSnackBar('Deleted Successfully', 'success');
        fetchSwagger();
      })
      .catch(() => {
        showSnackBar('Unable to delete', 'error');
      });
  };

  return { rows, deleteSwagger, fetchSwagger };
};

export default useSwaggerData;
