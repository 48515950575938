import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import i18n from 'config/lang/i18n';
import en from 'config/lang/en.png';
import nl from 'config/lang/nl.png';

//===============================================
// private variables
//===============================================

const languages = [
  {
    lang: 'en',
    image: en
  },
  {
    lang: 'nl',
    image: nl
  }
];

//===============================================
// Component props interface
//===============================================

interface IProps {
  title?: string;
}

//===============================================
// Component render function
//===============================================

export default function Language(props: IProps) {
  const { title } = props;
  const [open, setOpen] = useState(false);
  const language = i18n.language;

  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 50 }}>
        <Tooltip
          title={title}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 18]
                  }
                }
              ]
            }
          }}>
          <Select
            id='SelectLanguage'
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            open={open}
            value={language}
            onChange={handleChange}
            onClose={handleClose}
            onOpen={handleOpen}>
            {languages.map((option) => (
              <MenuItem value={option.lang} key={option.lang}>
                <img src={option.image} alt={option.lang} />
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
    </div>
  );
}
