

function IconMore(_props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3697"></path>
            <path
                fill={_props.color ? _props.color : "#00005a"}
                d="M6 10a2 2 0 102 2 2.006 2.006 0 00-2-2zm12 0a2 2 0 102 2 2.006 2.006 0 00-2-2zm-6 0a2 2 0 102 2 2.006 2.006 0 00-2-2z"
                data-name="Path 3698"
            ></path>
        </svg>
    );
}

export default IconMore;