import React from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import { InputLable } from 'component/organisms/InputLabel/InputLabel';
import { OutlinedFormInput } from '../OutlinedInput/OutlinedInput';

const formControllWrapper = {
  width: '35ch',
  mt: 2,
  'label+.MuiOutlinedInput-root': { mt: 0 },
  '.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-input': { border: 'none' },
  '.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root': { background: '#f2f2f2' },
  '.MuiFormControl-root': { pr: '3px' },
  '.MuiFormControl-root .MuiOutlinedInput-notchedOutline': { border: 'none', borderColor: '#fff' }
};

export default function Phonenumber() {
  const { t } = useTranslation<string>();

  const [phone, setPhone] = React.useState('Work');

  const handleChange = (event: SelectChangeEvent) => {
    setPhone(event.target.value);
  };

  return (
    <FormControl sx={formControllWrapper} variant='standard'>
      <InputLable htmlFor='phone'> {t('phoneNumber')}</InputLable>
      <OutlinedFormInput
        id='phoneOutline'
        endAdornment={
          <InputAdornment sx={{ minWidth: 110 }} position='end'>
            <FormControl variant='outlined' sx={{ m: 2, minWidth: 110, border: 0, pr: 1.5 }}>
              <Select labelId='phone' id='phone' value={phone} onChange={handleChange} className='selectStyle'>
                <MenuItem selected value={'Work'}>
                  {t('work')}
                </MenuItem>
                <MenuItem value={'Private'}>{t('private')}</MenuItem>
              </Select>
            </FormControl>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
