export const ensureNumber = (value, default_value) => {
    const isNumber = typeof value === 'number' || value;
    return isNumber ? Number(value) : default_value;
};
export const ensureInRange = (min, max, value) => {
    if (value <= min)
        return min;
    if (value >= max)
        return max;
    return value;
};
