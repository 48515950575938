import { useState, ChangeEvent, FormEvent } from 'react';
import { Box, Stack } from '@mui/material';
import { getToken, getTokenProperty } from 'context/Keycloak/Keycloak';
import { StyledButton } from 'component/atoms/Buttons/ButtonStyle';
import { Emailtypefield } from 'component/organisms/Emailtypefield/Emailtypefield';
import { InputPassword } from 'component/organisms/InputPassword/InputPassword';
import { Inputfield } from 'component/organisms/Inputfield/Inputfield';
import { useSnackBar } from 'context/Snackbar/Snackbar';

interface Payload {
  Email: string;
  Password: string;
  Username: string;
  Characteristic: string;
}

const UserAssociate = () => {
  const username = getTokenProperty('preferred_username');
  const email = getTokenProperty('email');
  const { showSnackBar } = useSnackBar();

  const initialPayload: Payload = {
    Email: email,
    Password: '',
    Username: username,
    Characteristic: 'EMPLOYEE'
  };

  const [payload, setPayload] = useState<Payload>(initialPayload);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail(payload.Email)) {
      alert('Please enter a valid email address.');
      return;
    }

    if (!validatePassword(payload.Password)) {
      alert('Please enter a valid password (at least 6 characters).');
      return;
    }

    if (!validateUsername(payload.Username)) {
      alert('Please enter a valid username.');
      return;
    }

    if (!validateCharacteristic(payload.Characteristic)) {
      alert('Please enter a valid Characteristic.');
      return;
    }

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`
      },
      body: JSON.stringify(payload)
    };

    fetch('https://vp-dev-infinity.dev-1.dip.ccs.nl/gateway/connect/v3/users/actions/associate-user', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        showSnackBar('User associated successfully !!!', 'success');
      })
      .then((data) => {
        console.log('API Response:', data);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  const handleCancel = () => {
    setPayload(initialPayload);
  };

  const validateEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePassword = (password: string): boolean => {
    return password.length >= 6;
  };

  const validateUsername = (username: string): boolean => {
    return username.trim().length > 0;
  };

  const validateCharacteristic = (characteristic: string): boolean => {
    return characteristic.trim().length > 0;
  };

  return (
    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'left', p: 2.5, flexGrow: 1 }}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ width: '100%', p: 2.5, pb: 5 }}>
          <Emailtypefield name='Email' value={payload.Email} onChange={handleChange} />
        </Box>
        <Box sx={{ width: '100%', p: 2.5 }}>
          <InputPassword name='Password' value={payload.Password} onChange={handleChange} />
        </Box>
        <Box sx={{ width: '100%', p: 2.5 }}>
          <Inputfield name='Username' label='Username' value={payload.Username} onChange={handleChange} />
        </Box>
        <Box sx={{ width: '100%', p: 2.5 }}>
          <Inputfield
            name='Characteristic'
            label='Characteristic'
            value={payload.Characteristic}
            onChange={handleChange}
          />
        </Box>
        <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
          <StyledButton type='submit' variant='contained'>
            Submit
          </StyledButton>
          <StyledButton type='button' variant='contained' onClick={handleCancel}>
            Reset
          </StyledButton>
        </Stack>
      </form>
    </Box>
  );
};

export default UserAssociate;
