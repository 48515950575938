import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

const FormioReportIcons = {
  AssessmentOutlinedIcon,
  CloseFullscreenOutlinedIcon,
  OpenInFullOutlinedIcon,
  EditNoteOutlinedIcon,
  ChatBubbleOutlineOutlinedIcon,
  EventNoteIcon,
  BookmarkBorderOutlinedIcon
};

export default FormioReportIcons;
