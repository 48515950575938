import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material';

export const TogglegroupSwitch = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: '10px !important',
  marginBottom: 20,
  width: '120px',
  background: '#fff',
  border: '1px solid lightgrey !important',
  color: theme.palette.primary.main
}));
