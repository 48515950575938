import { useTheme } from '@mui/material/styles';
function IconSlideRight(_props: any) {
  const theme = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      data-name='Layer 1'
      viewBox='0 0 100 125'
      width={_props.width ? _props.width : '38'}
      height={_props.height ? _props.height : '38'}>
      <path
        fill={_props.color ? _props.color : theme.palette.primary.main}
        d='M47.76 62L52 66.24 66.12 52.12a3 3 0 000-4.24L52 33.76 47.76 38 57 47h-23v6h23z'></path>
      <path fill={_props.color ? _props.color : theme.palette.primary.main} d='M81 23H75V77H81z'></path>
    </svg>
  );
}

export default IconSlideRight;
