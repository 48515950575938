declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  PUBLIC_URL: string;
  REACT_APP_DIP_SERVER_URL: string;
  REACT_APP_KC_ORIGIN: string;
  REACT_APP_KC_REALM: string;
  REACT_APP_KC_CLIENT_ID: string;
  REACT_APP_DS_ORIGIN: string;
  REACT_APP_DS_STAGE_PATH: string;
  REACT_APP_FORMIO_API: string;
};

export const env: EnvType = { ...process.env, ...window.env };
