import ReactDOM from 'react-dom/client';
import premium from '@formio/premium';
import reportWebVitals from './reportWebVitals';
import { Formio } from 'formiojs';
import { LicenseInfo } from '@mui/x-license-pro';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import config from 'config/config';
import { KeycloakProvider } from 'context/Keycloak/Keycloak';
import { persistor, store } from 'store/store';
import { createAuthorizationFetchPlugin } from 'utils/formio/createAuthorizationFetchPlugin';
import { FormioRuntimeSupport } from 'utils/formio/FormioRuntimeSupport';
import { replaceEditFormFunctions } from 'utils/formio/replaceEditFormFunctions';

import 'config/lang/i18n';

import './index.css';

//===============================================
// initialize formio
//===============================================

Formio.use(premium);
Formio.setBaseUrl(config.server_url.slice(0, -1));
Formio.setProjectUrl(`${config.server_url}formio-form`);
Formio.registerPlugin(createAuthorizationFetchPlugin(), createAuthorizationFetchPlugin.name);

replaceEditFormFunctions();

//===============================================
// initialize formio support library
//===============================================

window.formio_support = new FormioRuntimeSupport();

//===============================================
// initialize mui-pro
//===============================================

LicenseInfo.setLicenseKey(
  '79e5dda7d608609bc5abcbd5ac4b8c7dTz04NzEyMCxFPTE3NDI5NzYxNDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

//===============================================
// initialize react
//===============================================

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <KeycloakProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </KeycloakProvider>
);

reportWebVitals();
