import { useTheme } from '@mui/material/styles';

function IconUpload2(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <g data-name="Group 7042">
                <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2436"></path>
            </g>
            <g data-name="Group 7044">
                <g data-name="Group 7043">
                    <path
                        fill={_props.color ? _props.color : theme.palette.primary.main}
                        d="M14 2H6a2 2 0 00-1.99 2L4 20a2 2 0 001.99 2H18a2.006 2.006 0 002-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
                        data-name="Path 3731"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default IconUpload2;