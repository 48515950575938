import { Grid, Typography } from '@mui/material';
import { countries } from './Constants';
import { Theme } from '@mui/material/styles';
import ArchiveService from 'entities/Archive/ArchiveService';
import { IArchive } from 'entities/Archive/IArchive';
import { ChangeEvent } from 'react';
import { IComponent } from '@ccs-dip/common/types/formio-types';
import { LanguageTranslation } from 'component/pages/Translation/ITranslationDataApi';

export function filterPolicies(data: any): any {
  return {
    Policies: data.Policies.map((policyGroup: { Policy: any[] }) => ({
      Policy:
        policyGroup.Policy.length === 1
          ? policyGroup.Policy
          : policyGroup.Policy.filter((policy) => policy.LOBCd === 'PKG')
    }))
  };
}

export const keyGenerator = () => '_' + Math.random().toString(36).substr(2, 9);

export const getCountryCode = (input: any) => {
  const arr = countries.find((itm) => itm.phone === input);
  return arr?.code.toLowerCase() || 'ad';
};

export const getStyles = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightRegular
  };
};

export const readFileAsJson = <T,>(file: File): Promise<T> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = JSON.parse(e.target?.result as string);
        resolve(data as T);
      } catch (error) {
        reject(new Error('Invalid JSON format'));
      }
    };
    reader.onerror = () => reject(new Error('File reading failed'));
    reader.readAsText(file);
  });
};
export const addCustomClassToButtons = (components: IComponent[]) => {
  components.forEach((component) => {
    if (component.type === 'button') {
      if (component.customClass) {
        // Check if the class 'custom-button-class' is already present
        if (!component.customClass.split(' ').includes('custom-button-class')) {
          component.customClass += ' custom-button-class';
        }
      } else {
        component.customClass = 'custom-button-class';
      }
    }
    console.log(component.label, component);
    if (component.components && component.components.length > 0) {
      addCustomClassToButtons(component.components);
    }
  });
  return components;
};
export const isValidLanguageTranslation = (translations: LanguageTranslation[]): boolean => {
  // Check if translations is an array
  if (!Array.isArray(translations)) {
    return false;
  }

  // Check each translation object in the array
  for (const obj of translations) {
    // Check if obj is an object and has a data property
    if (typeof obj !== 'object' || obj === null || !obj.data) {
      return false;
    }

    const { data } = obj;

    // Check if data is an object
    if (typeof data !== 'object' || data === null) {
      return false;
    }

    // Check if data has a language property of type string
    if (typeof data.language !== 'string') {
      return false;
    }

    // Check if data has a translation property that is an object
    if (typeof data.translation !== 'object' || data.translation === null) {
      return false;
    }

    // Check if all values in translation are strings
    for (const key in data.translation) {
      if (typeof data.translation[key] !== 'string') {
        return false;
      }
    }
  }

  // If all checks pass, return true
  return true;
};

export const caseinsensitive = (string: string) => {
  if (string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const readFileAsText = <T,>(file: File): Promise<T> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = e.target?.result as string;
        resolve(data as T);
      } catch (error) {
        reject(new Error('Invalid JSON format'));
      }
    };
    reader.onerror = () => reject(new Error('File reading failed'));
    reader.readAsText(file);
  });
};

export const isValidSwaggerFile = (file: File): boolean => {
  return /.json|.yml|.yaml$/i.test(file.name);
};

export const isValidjsonFile = (file: File): boolean => {
  return /.json/i.test(file.name);
};

export const clearFile = (event: ChangeEvent<HTMLInputElement>): void => {
  if (event.target) {
    event.target.value = '';
  }
};

export const toJsonExtension = (name: string) => {
  const replaceName = name.replace(/\.ya?ml/g, '.json');
  return replaceName;
};

export const downloadFormAsJson = (fileContent: object | any[]) => {
  const jsonString = JSON.stringify(fileContent, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  // Determine the filename
  const defaultFilename = 'language.json';
  let filename = defaultFilename;

  if (Array.isArray(fileContent)) {
    filename =
      fileContent.length > 0 && typeof fileContent[0] === 'object' && fileContent[0] !== null
        ? (fileContent[0] as { name?: string }).name || defaultFilename
        : defaultFilename;
  } else if (typeof fileContent === 'object' && fileContent !== null) {
    filename = (fileContent as { name?: string }).name || defaultFilename;
  }

  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const isValidSwaggerJson = (json: any) => {
  if (typeof json !== 'object' || json === null) {
    return false;
  }

  if (!('info' in json) || !('paths' in json)) {
    return false;
  }

  if (typeof json.info !== 'object' || json.info === null) {
    return false;
  }

  if (!('title' in json.info) || !('version' in json.info)) {
    return false;
  }

  if (typeof json.info.title !== 'string' || typeof json.info.version !== 'string') {
    return false;
  }

  if (typeof json.paths !== 'object' || json.paths === null) {
    return false;
  }

  return true;
};

export function camelize(str: string): string {
  const cleanedStr = str.replace(/[^a-zA-Z\s]/g, '');
  const words = cleanedStr.split(' ').filter(Boolean);
  words.forEach((word, index) => {
    if (index > 0) {
      words[index] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  const camelizedStr = words.join('');
  return camelizedStr ? camelizedStr.charAt(0).toLowerCase() + camelizedStr.slice(1) : '';
}

export function sanitizeText(str: string): string {
  const cleanedStr = str.replace(/[^a-zA-Z1-9\s]/g, '');
  const words = cleanedStr.split(' ').filter(Boolean);
  const lowerCaseWords = words.reduce((acc: string[], itm: string) => {
    itm.toLowerCase();
    acc.push(itm);
    return acc;
  }, []);
  const clearStr = lowerCaseWords.join('');
  return clearStr;
}

export function generateRandomId(length = 10) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}
const truncateToKeywordsCamelCase = (sentence: string, maxWords = 3, maxKeywordLength = 15) => {
  return sentence
    .replace(/[^\w\s]/g, '')
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .slice(0, maxWords)
    .map((word) => word.slice(0, maxKeywordLength))
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('');
};

export const updateKey = (obj: any, updateKey: boolean = false) => {
  if (!updateKey && obj.key && obj.key !== '$$empty') return obj;

  const getValue = (lang: 'nl' | 'en') => obj[lang]?.default || obj[lang]?.custom;
  const value = getValue('en') || getValue('nl');

  obj.key = value ? truncateToKeywordsCamelCase(value) : '$$empty';

  return obj;
};

export const contentText = {
  color: '#00005A',
  fontSize: '14px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};

export const getPhoneNumber = (itm: any) => {
  return (
    <>
      {itm
        ? itm.map((item: any, index: any) => (
            <Grid container spacing={2} key={index} item xs={12} alignItems='center'>
              <Grid item xs={6}>
                <Typography component={'span'} sx={contentText}>
                  {item.PhoneTypeCd}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={'span'} sx={contentText}>
                  {item.PhoneNumber}
                </Typography>
              </Grid>
            </Grid>
          ))
        : ''}
    </>
  );
};

export const getCurrencySymbol = (currencyCode: string) => {
  const currencySymbols: { [key: string]: string } = { EUR: '€ ' };
  return currencySymbols[currencyCode] || '';
};

export function archiveDownload(archiveNumber: string, showSnackBar: Function) {
  const service = new ArchiveService();

  service
    .get(archiveNumber)
    .then((archive: IArchive) => {
      const { FileAttachmentInfo } = archive;
      const { AttachmentData, MIMEContentTypeCd, AttachmentFilename } = FileAttachmentInfo;
      const binaryString = atob(AttachmentData.BinData);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: MIMEContentTypeCd.Value });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = AttachmentFilename + '.' + MIMEContentTypeCd.Value;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch(() => {
      showSnackBar('Error reading file', 'error');
    });
}

// Utility function to check if an object has at least one non-null, non-undefined value
export const hasValues = (obj: any): boolean => {
  if (!obj || typeof obj !== 'object') return false;
  return Object.values(obj).some((value) => value !== null && value !== undefined && value !== '');
};
