
import { useTheme } from '@mui/material/styles';
function IconRoundTick(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "18"}
            height={_props.height ? _props.height : "18"}
            data-name="Component 39 – 157"
            viewBox="0 0 18 18"
        >
            <circle
                cx="9"
                cy="9"
                r="9"
                fill={_props.color ? _props.color : theme.palette.secondary.main}
                data-name="Ellipse 478"
            ></circle>
            <path
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M13.072 6.611l-4.787 4.748-2.849-2.694"
                data-name="Path 241"
            ></path>
        </svg>
    );
}

export default IconRoundTick;