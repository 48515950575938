import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';
import { IFileUpload } from './IFormDataGrid';
import { uploadButton, VisuallyHiddenInput } from './FormGridStyledComponent';

export default function UploadFormJson(props: IFileUpload) {
  const { t } = useTranslation();
  return (
    <Button component='label' sx={uploadButton} variant='contained' tabIndex={-1} startIcon={<CloudUploadIcon />}>
      {t('import')}
      <VisuallyHiddenInput
        onChange={(event) => {
          props.handleFileUpload(event);
        }}
        type='file'
      />
    </Button>
  );
}
