import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';
import { uploadButton, VisuallyHiddenInput } from './SwaggerGridStyledComponent';
import { ChangeEvent } from 'react';

interface UploadSwaggerFileProps {
  handleFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function UploadSwaggerFile(props: UploadSwaggerFileProps) {
  const { t } = useTranslation();
  return (
    <Button component='label' sx={uploadButton} variant='contained' tabIndex={-1} startIcon={<CloudUploadIcon />}>
      {t('import')}
      <VisuallyHiddenInput
      accept=".yaml,.yml,.json"
        onChange={(event) => {
          props.handleFileUpload(event);
        }}
        type='file'
      />
    </Button>
  );
}
