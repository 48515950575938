export class FieldInfo {
    path;
    name;
    type;
    propertyNames;
    format;
    minLength;
    maxLength;
    required;
    enum;
    constructor(path, type, propertyNames) {
        this.path = path;
        this.name = path.substring(path.lastIndexOf('.') + 1);
        this.type = type;
        this.propertyNames = propertyNames.sort();
    }
}
export const parseFieldName = (fieldName) => {
    const regex = new RegExp('^(?<path>([a-z]+\\.)*)(?<field>[a-z]+)?$', 'i');
    const match = regex.exec(fieldName);
    if (match && match.groups) {
        const path = match.groups['path']?.slice(0, -1).toLowerCase() ?? '';
        const field = match.groups['field']?.toLowerCase() ?? '';
        return { isValid: true, path, field };
    }
    return { isValid: false, path: '', field: '' };
};
