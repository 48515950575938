import { env } from './env';
import BaseConfig from '@ccs-dip/common/config/BaseConfig';
import { KeycloakConfig } from 'keycloak-js';

//=============================================
// private variables
//=============================================

const getLocation = (): string => {
  const location: any = window?.location;
  return `${location?.protocol}//${location?.host}`;
};

const getServerUrl = (): string => {
  const server_url: string = env.REACT_APP_DIP_SERVER_URL;
  return server_url ? server_url : getLocation();
};

const createConfig = () => {
  const config = new Config();

  config.basename = env.PUBLIC_URL;
  config.server_url = new URL('/api/', getServerUrl()).href;
  config.redirect_url = new URL(env.PUBLIC_URL, getLocation()).href;

  config.keycloak.url = new URL('/auth/', env.REACT_APP_KC_ORIGIN).href;
  config.keycloak.clientId = env.REACT_APP_KC_CLIENT_ID;
  config.keycloak.realm = env.REACT_APP_KC_REALM;

  config.formio.base_url = env.REACT_APP_DS_ORIGIN;
  config.formio.project_url = new URL(env.REACT_APP_DS_STAGE_PATH, env.REACT_APP_DS_ORIGIN).href;
  config.formio.env_url = env.REACT_APP_FORMIO_API;
  config.validate();
  return config;
};

//=============================================
// Config class
//=============================================

class Config extends BaseConfig {
  basename = '';
  server_url = '';
  timeout = 30000;
  redirect_url = '';

  keycloak: KeycloakConfig = {
    url: '',
    realm: '',
    clientId: ''
  };

  formio = {
    base_url: '',
    project_url: '',
    env_url: ''
  };

  override validate_fields(): void {
    // validate main fields
    this.require('server_url', this.server_url);
    this.require('timeout', this.timeout);
    this.require('redirect_url', this.redirect_url);
    // validate keycloak fields
    this.require('keycloak.url', this.keycloak.url);
    this.require('keycloak.realm', this.keycloak.realm);
    this.require('keycloak.clientId', this.keycloak.clientId);

    // validate formio fields
    this.require('formio.base_url', this.formio.base_url);
    this.require('formio.project_url', this.formio.project_url);
    this.require('formio.env_url', this.formio.env_url);
  }
}

//=============================================
// main
//=============================================

const config = createConfig() as Readonly<Config>;

export default config;
