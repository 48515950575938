import { OutlinedInput, styled } from '@mui/material';
export const OutlinedFormInput = styled(OutlinedInput)(({ theme }) => ({
  '& label+.MuiOutlinedInput-root': { marginTop: 0 },
  '.MuiFormControl-root label+.MuiOutlinedInput-root': { marginTop: 0 },
  color: theme.palette.primary.main,
  backgroundColor: '#fff',
  mt: 2,
  mb: 2,
  'label+.MuiOutlinedInput-root': { marginTop: 0 },
  'input.MuiInputBase-inputAdornedEnd': { border: 0 },
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.secondary.main
  },
  background: '#fff',
  '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: '#fa6d6d'
  }
}));
