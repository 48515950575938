
function IconCalendarApproved(_props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3733"></path>
            <path
                fill={_props.color ? _props.color : "#00005a"}
                d="M19 3h-1V1h-2v2H8V1H6v2H5a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2zm0 16H5V9h14zM5 7V5h14v2zm5.56 10.46l5.93-5.93-1.06-1.06-4.87 4.87-2.11-2.11-1.06 1.06z"
                data-name="Path 3734"
            ></path>
        </svg>
    );
}

export default IconCalendarApproved;