
import { useTheme } from '@mui/material/styles';
function IconArrowRight(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2433"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M14.29 5.71a1 1 0 000 1.41L18.17 11H3a1 1 0 00-1 1 1 1 0 001 1h15.18l-3.88 3.88a1 1 0 000 1.41 1 1 0 001.41 0l5.59-5.59a1 1 0 000-1.41l-5.6-5.58a1 1 0 00-1.41 0z"
                data-name="Path 3686"
            ></path>
        </svg>
    );
}

export default IconArrowRight;