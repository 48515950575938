import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Box,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PolicyService from 'entities/Policy/PolicyService';
import { useTheme } from '@mui/material/styles';
//import { IPolicy } from 'entities/Policy/IPolicy';
import { contentText, getCurrencySymbol } from 'utils/Utils';
import Loader from 'component/organisms/Loader/Loader';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  avatar,
  backDrop,
  coverageDetails,
  editDetails,
  insuranceDetails,
  moreDetails,
  paymentDetails,
  //scrollableContent,
  wrapperText,
  wrapper
} from './PolicyBriefViewStyles';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import React from 'react';

interface IPolicyBriefViewProps {
  policyNumber: string;
  policyVersion: string;
}

export const PolicyBriefView = (props: IPolicyBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const [policyDetails, setPolicyDetails] = useState<any[]>();
  const service = new PolicyService();
  const { showSnackBar } = useSnackBar();

  const getPolicyDetails = () => {
    return service
      .get(props.policyNumber + '?PolicyVersion=' + props.policyVersion ?? '')
      .then((policy: any) => {
        setPolicyDetails(policy.policyMessage.Policy);
        setisloading(false);
      })
      .catch((error: any) => {
        showSnackBar(error.message, 'error');
        setPolicyDetails(undefined);
        setisloading(false);
      });
  };

  useEffect(() => {
    setisloading(true);
    props.policyNumber && getPolicyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.policyNumber]);

  return (
    <>
      {isloading ? (
        <Backdrop sx={backDrop} open={isloading} onClick={() => {}}>
          <Loader></Loader>
        </Backdrop>
      ) : (
        <Box id='PolicyBriefView' sx={{ p: 3 }}>
          <Stack direction={'row'} spacing={1} alignItems='center' sx={{ mb: 2 }}>
            <Avatar sx={avatar}>
              <Typography>PD</Typography>
            </Avatar>
            <Stack direction='column' spacing={1}>
              {policyDetails?.[0]?.LeadInsurerName && (
                <>
                  <Box sx={wrapper}>
                    <Tooltip title={policyDetails?.[0]?.LeadInsurerName}>
                      <Typography id={`HeaderLeadInsurerName`} sx={wrapperText}>
                        {policyDetails?.[0]?.LeadInsurerName}
                      </Typography>
                    </Tooltip>
                  </Box>
                </>
              )}

              {policyDetails?.[0]?.CompanysPolicyNumber && (
                <>
                  <Typography
                    id={`HeaderCompanysPolicyNumber`}
                    sx={{ fontWeight: 800, fontSize: '14px', color: (theme) => theme.palette.primary.main }}>
                    #{policyDetails?.[0]?.CompanysPolicyNumber}
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>

          {(policyDetails?.[0]?.OriginalPolicyInceptionDt ||
            policyDetails?.[0]?.ContractTerm.ExpirationDt ||
            policyDetails?.[0]?.ContractTerm.DurationPeriod.NumUnits ||
            policyDetails?.[0]?.PaymentOption?.[0]?.BillingPeriod?.DurationPeriod?.NumUnits ||
            policyDetails?.[0]?.Account?.AccountNumberId?.Value ||
            policyDetails?.[0]?.PaymentOption?.[0]?.MethodPaymentRefCd?._Desc) && (
            <>
              <Divider />
              <Typography sx={insuranceDetails}>{t('insuranceDetails')}</Typography>
              <Grid container spacing={2}>
                {policyDetails?.[0]?.OriginalPolicyInceptionDt && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>{t('originalPolicyInceptionDt')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id={`OriginalPolicyInceptionDt`} sx={contentText}>
                        {policyDetails?.[0]?.OriginalPolicyInceptionDt}
                      </Typography>
                    </Grid>
                  </>
                )}

                {policyDetails?.[0]?.ContractTerm?.ExpirationDt && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>{t('expirationDt')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id={`ExpirationDt`} sx={contentText}>
                        {policyDetails?.[0]?.ContractTerm?.ExpirationDt}
                      </Typography>
                    </Grid>
                  </>
                )}

                {policyDetails?.[0]?.ContractTerm?.DurationPeriod?.NumUnits && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>{t('contractTerm')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id={`ContractTerm`} sx={contentText}>
                        {policyDetails?.[0]?.ContractTerm?.DurationPeriod?.NumUnits}
                      </Typography>
                    </Grid>
                  </>
                )}

                {policyDetails?.[0]?.PaymentOption?.[0]?.BillingPeriod?.DurationPeriod?.NumUnits && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>{t('billingPeriod')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id={`BillingPeriod`} sx={contentText}>
                        {policyDetails?.[0]?.PaymentOption?.[0]?.BillingPeriod?.DurationPeriod?.NumUnits}
                      </Typography>
                    </Grid>
                  </>
                )}

                {policyDetails?.[0]?.Account?.AccountNumberId?.Value && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>{t('ibanNumber')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id={`IbanNumber`} sx={contentText}>
                        {policyDetails?.[0]?.Account?.AccountNumberId?.Value}
                      </Typography>
                    </Grid>
                  </>
                )}

                {policyDetails?.[0]?.PaymentOption?.[0]?.MethodPaymentRefCd?._Desc && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>{t('paymentMethod')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id={`PaymentMethod`} sx={contentText}>
                        {policyDetails?.[0]?.PaymentOption?.[0]?.MethodPaymentRefCd?._Desc}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}

          {policyDetails &&
            policyDetails.map((policy, index) => (
              <React.Fragment key={index}>
                <Stack spacing={1}>
                  <Accordion
                    key={index}
                    sx={{
                      border: 'black',
                      borderRadius: 8,
                      backgroundColor: theme.palette.background.default
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel1-content-${index}-${index}`}
                      id={`panel1-header-${index}-${index}`}
                      sx={{
                        border: 'black',
                        mt: 1.2,
                        backgroundColor: theme.palette.background.default
                      }}>
                      <Typography sx={insuranceDetails}>
                        {policy.LOBDesc + '-' + policy.CompanysPolicyNumber}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ backgroundColor: theme.palette.background.default }}>
                      {(policy.CompanysPolicyNumber ||
                        policy.LOBDesc ||
                        policy?.PolicyNumber ||
                        policy.LeadInsurerName ||
                        policy.OriginalPolicyInceptionDt ||
                        policy.ContractTerm.ExpirationDt ||
                        policy.ContractTerm.DurationPeriod.NumUnits) && (
                        <Grid container spacing={2}>
                          {policy?.CompanysPolicyNumber && (
                            <>
                              <Grid item xs={6}>
                                <Typography sx={contentText}>{t('policyNumber')}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography id={`CompanysPolicyNumber-${index}`} sx={contentText}>
                                  {policy?.CompanysPolicyNumber}
                                </Typography>
                              </Grid>
                            </>
                          )}

                          {policy?.PolicyNumber && (
                            <>
                              <Grid item xs={6}>
                                <Typography sx={contentText}>{t('internalPolicyNumber')}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography id={`InternalPolicyNumber-${index}`} sx={contentText}>
                                  {policy?.PolicyNumber}
                                </Typography>
                              </Grid>
                            </>
                          )}

                          {policy?.LOBDesc && (
                            <>
                              <Grid item xs={6}>
                                <Typography sx={contentText}>{t('lOBDesc')}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography id={`LOBDesc-${index}`} sx={contentText}>
                                  {policy?.LOBDesc}
                                </Typography>
                              </Grid>
                            </>
                          )}

                          {policy?.LeadInsurerName && (
                            <>
                              <Grid item xs={6}>
                                <Typography sx={contentText}>{t('leadInsurerName')}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography id={`LeadInsurerName-${index}`} sx={contentText}>
                                  {policy?.LeadInsurerName}
                                </Typography>
                              </Grid>
                            </>
                          )}

                          {policy?.OriginalPolicyInceptionDt && (
                            <>
                              <Grid item xs={6}>
                                <Typography sx={contentText}>{t('originalPolicyInceptionDt')}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography id={`OriginalPolicyInceptionDt-${index}`} sx={contentText}>
                                  {policy?.OriginalPolicyInceptionDt}
                                </Typography>
                              </Grid>
                            </>
                          )}

                          {policy?.ContractTerm?.ExpirationDt && (
                            <>
                              <Grid item xs={6}>
                                <Typography sx={contentText}>{t('expirationDt')}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography id={`ExpirationDt-${index}`} sx={contentText}>
                                  {policy?.ContractTerm?.ExpirationDt}
                                </Typography>
                              </Grid>
                            </>
                          )}

                          {policy?.ContractTerm?.DurationPeriod?.NumUnits && (
                            <>
                              <Grid item xs={6}>
                                <Typography sx={contentText}>{t('contractTerm')}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography id={`ContractTerm-${index}`} sx={contentText}>
                                  {policy?.ContractTerm?.DurationPeriod?.NumUnits}
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}

                      {Object.keys(policy).map((lineBusinessKey) => {
                        if (lineBusinessKey.endsWith('LineBusiness') && policy[lineBusinessKey]?.Coverage?.length > 0) {
                          return (
                            <React.Fragment key={lineBusinessKey}>
                              <Divider />
                              <Typography sx={coverageDetails}>{t('coverageDetails')}</Typography>
                              {policy[lineBusinessKey].Coverage.map((coverage: any, coverageIndex: number) => (
                                <Accordion key={coverageIndex}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel1-content-${index}-${coverageIndex}`}
                                    id={`panel1-header-${index}-${coverageIndex}`}>
                                    <Typography variant='subtitle2'>{coverage.CoverageDesc}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <Typography sx={contentText}>{t('coverageDesc')}</Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography id={`CoverageDesc-${index}-${coverageIndex}`} sx={contentText}>
                                          {coverage.CoverageDesc}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography sx={contentText}>{t('netAmt')}</Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography id={`NetAmt-${index}-${coverageIndex}`} sx={contentText}>
                                          {getCurrencySymbol(coverage.NetAmt?.CurCd?.Value ?? '')}
                                          {coverage.NetAmt?.Amt}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              ))}
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}

                      {(policy.GrossPremiumAmt?.Amt || policy.TaxAmt?.Amt || policy.RenewalPremiumAmt?.Amt) && (
                        <>
                          <Divider />
                          <Typography sx={paymentDetails}>{t('paymentDetails')}</Typography>

                          <Grid container spacing={2}>
                            {policy?.GrossPremiumAmt?.Amt && (
                              <>
                                <Grid item xs={6}>
                                  <Typography sx={contentText}>{t('grossPremiumAmt')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography id={`GrossPremiumAmt-${index}`} sx={contentText}>
                                    {getCurrencySymbol(policy.GrossPremiumAmt?.CurCd?.Value ?? '')}
                                    {policy?.GrossPremiumAmt?.Amt}
                                  </Typography>
                                </Grid>
                              </>
                            )}

                            {policy?.TaxAmt?.Amt && (
                              <>
                                <Grid item xs={6}>
                                  <Typography sx={contentText}>{t('taxAmt')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography id={`TaxAmt-${index}`} sx={contentText}>
                                    {getCurrencySymbol(policy.TaxAmt?.CurCd?.Value ?? '')} {policy?.TaxAmt?.Amt}
                                  </Typography>
                                </Grid>
                              </>
                            )}

                            {policy?.RenewalPremiumAmt?.Amt && (
                              <>
                                <Grid item xs={6}>
                                  <Typography sx={contentText}>{t('renewalPremiumAmt')}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography id={`RenewalPremiumAmt-${index}`} sx={contentText}>
                                    {getCurrencySymbol(policy.RenewalPremiumAmt?.CurCd?.Value ?? '')}
                                    {policy?.RenewalPremiumAmt?.Amt}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </>
                      )}

                      <Divider />
                      <Stack direction={'row'} spacing={1} justifyContent='space-between'>
                        <Stack direction={'column'} spacing={1}>
                          <Typography
                            id={`MoreDetails-${index}`}
                            variant='subtitle2'
                            display='block'
                            sx={moreDetails}
                            gutterBottom>
                            <Link to={`#`} style={{ color: theme.palette.secondary.main }}>
                              {t('moreDetails')}
                            </Link>
                          </Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={1}>
                          <Typography
                            id={`Edit-${index}`}
                            variant='subtitle2'
                            display='block'
                            sx={editDetails}
                            gutterBottom>
                            <Link to={`#`} style={{ color: theme.palette.secondary.main }}>
                              {t('edit')}
                            </Link>
                          </Typography>
                        </Stack>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </React.Fragment>
            ))}
        </Box>
      )}
    </>
  );
};

export default PolicyBriefView;
