/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import { Form, Formio } from '@formio/react';

import { useSnackBar } from 'context/Snackbar/Snackbar';
import { createErrorFetchPlugin } from 'utils/formio/createErrorFetchPlugin';
import { setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import { LanguageCode, NamespaceCode } from 'entities/Translation/TranslationService';

import './FormStyles.css';
import FormioService from 'entities/Formio/FormioService';
import { Box } from '@mui/material';
import { IForm, IFormioSubmissionResponse } from '@ccs-dip/common/types/formio-types';
import { FormioSubmissionService } from 'entities/FormioSubmission/FormioSubmissionService';
import config from 'config/config';

//===============================================
// private variables
//===============================================

const getInitialSubmission = (partynumber: string | undefined) => {
  const initial_data = partynumber ? { PartyNumber: partynumber } : {};
  return { data: initial_data, metadata: {} };
};

const getTranslations = (i18n: i18n, lang: LanguageCode) => {
  const default_translations = i18n.getResourceBundle(lang, 'default' as NamespaceCode);
  const custom_translations = i18n.getResourceBundle(lang, 'custom' as NamespaceCode);
  return { ...default_translations, ...custom_translations };
};

interface IFormRenderer {
  formsrc?:string;
}

//===============================================
// Component render function
//===============================================

const FormRenderer = (props: IFormRenderer) => {
  const params = useParams();
  const formsrc = props.formsrc || params['formsrc'];
  const partynumber = params['partynumber'];
  const [instance, setInstance] = useState<any>();
  const [formio, setFormio] = useState<IForm | undefined>();
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const translations_nl = getTranslations(i18n, 'nl');
  const translations_en = getTranslations(i18n, 'en');
  const className = formsrc === 'insuranceoverview' ? 'hiddenPanel' : 'defaultForm';

  const service = new FormioService();
  // register error fetch-plugin
  Formio.registerPlugin(createErrorFetchPlugin(showSnackBar), createErrorFetchPlugin.name);

  useEffect(() => {
    dispatch(setOpenBriefView(false));
    setInstance(null);
      service
        .getForms(formsrc)
        .then((response: any) => {
          if (Object.keys(response).length) {
            if (response) {
              setFormio(response);
            }
            // Provide feedback to the user
          }
        })
        .catch(() => {
          showSnackBar('No forms available', 'error');
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance,formsrc]);

  useEffect(() => {
    if (instance) {
      // Warning instance will reset page after change language...!!!
      const page = instance.page;
      instance.language = i18n.language;
      if (instance.setPage) {
        setTimeout(() => instance.setPage(page), 3000);
      }
    }
    i18n.changeLanguage(i18n.language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, i18n.language]);

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', minHeight: 500 }}>

        <Box sx={{ width: '100%', p: '30px' }} className={className}>
          {formio && Object.keys(formio).length ? (
            <Box className={formio.display !== 'form' ? 'custom-form-builder-wizard' : ''}>
              <Form
                key={formsrc}
                form={formio}
                src={`${config.server_url}formio-form/${formsrc}`}
                formReady={async (instance: any) => {
                  setInstance(instance);
                  if (instance) {
                    instance.language = i18n.language;
                  }
                  if (partynumber) {
                    const service = new FormioSubmissionService();
                    const submission = getInitialSubmission(partynumber);

                    await service
                      .send(instance._form.name, 'readParty', submission)
                      .then((response: IFormioSubmissionResponse) => {
                        instance.submission = response.submission;
                      });
                  }
                }}
                options={{
                  i18n: {
                    language: i18n.language,
                    en: translations_en,
                    nl: translations_nl
                  }
                }}
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </>
  );
};

export default FormRenderer;
