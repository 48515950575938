import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Typography, IconButton } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { AccountMenuWithCancelItemProps, AccountMenuWithCancelProps } from './IAccountMenuProps';
import { accountMenuCancelStyle } from './AccountMenuCancelStyle';

export const AccountMenuCancelItem: React.FC<AccountMenuWithCancelItemProps> = ({ text, onCancel }) => (
  <MenuItem>
    <Typography component={'span'}>{text}</Typography>
    <IconButton onClick={onCancel} className='IconButtonStyle'>
      <ClearRoundedIcon />
    </IconButton>
  </MenuItem>
);

const AccountMenuWithCancel: React.FC<AccountMenuWithCancelProps> = ({ menuItems, buttonLabel = 'Menu' }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = (index: number) => {
    // Remove the item from the list
    const updatedMenuItems = [...menuItems];
    updatedMenuItems.splice(index, 1);
    // You may want to perform additional actions related to cancellation here
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} sx={{ textAlign: 'left' }}>
        <Typography  component={'span'} variant='body1' fontWeight='bold'>
          {buttonLabel}
        </Typography>
      </Button>
      <Menu
        sx={accountMenuCancelStyle}
        anchorEl={anchorEl}
        id='account-menu-cancel'
        open={open}
        onClose={handleClose}
        hideBackdrop
        variant='menu'
        onClick={handleClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}>
        {menuItems.map((item, index) => (
          <AccountMenuCancelItem key={index} {...item} onCancel={() => handleCancel(index)} />
        ))}
      </Menu>
    </>
  );
};

export default AccountMenuWithCancel;
