import { useTheme } from '@mui/material/styles';

function IconPlus(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3669"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M18 13h-5v5a1 1 0 01-2 0v-5H6a1 1 0 010-2h5V6a1 1 0 012 0v5h5a1 1 0 010 2z"
                data-name="Path 3670"
            ></path>
        </svg>
    );
}

export default IconPlus;