import { useTheme } from '@mui/material/styles';
function IconMail(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3719"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M22 6a2.006 2.006 0 00-2-2H4a2.006 2.006 0 00-2 2v12a2.006 2.006 0 002 2h16a2.006 2.006 0 002-2zm-2 0l-8 5-8-5zm0 12H4V8l8 5 8-5z"
                data-name="Path 3720"
            ></path>
        </svg>
    );
}

export default IconMail;