
import { useTheme } from '@mui/material/styles';
function IconChevronDown(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path
                fill="none"
                d="M24 24H0V0h24z"
                data-name="Path 3693"
                opacity="0.87"
            ></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M15.88 9.29L12 13.17 8.12 9.29a1 1 0 00-1.41 1.41l4.59 4.59a1 1 0 001.41 0l4.59-4.59a1 1 0 000-1.41 1.017 1.017 0 00-1.42 0z"
                data-name="Path 3694"
            ></path>
        </svg>
    );
}

export default IconChevronDown;