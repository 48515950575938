import { parseFieldName } from '@ccs-dip/common/FieldInfo';
import { ensureInRange } from '@ccs-dip/common/number';
import { OpenApiService } from 'entities/OpenApi/OpenApiService';

//===============================================
// types
//===============================================

type FieldSelectData = {
  initialized: boolean;
  path: string;
  fieldNames: string[];
};

type HTMLFieldSelectElement = HTMLInputElement & { data: FieldSelectData };

enum Direction {
  next = 1,
  previous = -1
}

//===============================================
// private variables
//===============================================

const getInitialData = (initialized = true) => {
  return { initialized, path: '', fieldNames: [
      'Claim',
      'Party',
      'Policy'
    ] };
};

const getFieldSelectElement = () => {
  const element = document.getElementById('fieldSelector') as HTMLFieldSelectElement | null;

  if (element && !element.data) {
    element.data = getInitialData(false);
  }

  return element;
};

const getDropdownElement = () => {
  return document.getElementById('autocomplete-list');
};

const createDropdownElement = (target: HTMLFieldSelectElement, field_part: string) => {
  const list = document.createElement('div');
  const lowercaseFieldPart = field_part.toLowerCase();

  const fields = target.data.fieldNames
    ? target.data.fieldNames.filter((item) => item.toLowerCase().startsWith(lowercaseFieldPart))
    : [];

  removeDropdownElement();

  list.id = 'autocomplete-list';
  list.className = 'autocomplete-items';
  list.style.maxHeight = '200px';
  list.style.overflowY = 'scroll';
  list.style.marginTop = '15px';

  target.parentNode?.appendChild(list);

  fields.forEach((field) => {
    const item = document.createElement('div');
    item.className = 'dropdown-item';
    item.addEventListener('click', dropdownItemClickEventHandler);

    const matchedPart = field.slice(0, field_part.length);
    const remainingPart = field.slice(field_part.length);

    item.innerHTML = `<strong>${matchedPart}</strong>${remainingPart}<input type='hidden' value='${field}'/>`;

    list.appendChild(item);
  });
};

const removeDropdownElement = () => {
  const dropdownElement = getDropdownElement();
  dropdownElement?.parentNode?.removeChild(dropdownElement);
};

const highlightItem = (items: HTMLCollectionOf<HTMLDivElement>, direction: Direction) => {
  const dropdownElement = getDropdownElement();

  if (dropdownElement) {
    const array = Array.from(items);
    const currentIndex = array.findIndex((item) => item.classList.contains('active'));
    const nextIndex = ensureInRange(0, array.length, currentIndex + direction);
    const nextItem = items[nextIndex];

    array[currentIndex]?.classList.remove('active');

    if (nextItem) {
      const itemBottom = nextItem.offsetTop + nextItem.offsetHeight;

      nextItem.classList.add('active');
      dropdownElement.scrollTop = 0;

      if (nextItem.offsetTop < 0) {
        dropdownElement.scrollTop = nextItem.offsetTop;
      } else if (itemBottom > dropdownElement.offsetHeight) {
        dropdownElement.scrollTop = itemBottom - dropdownElement.offsetHeight;
      }
    }
  }
};

const setKeyElementValue = (value: string) => {
  const inputElement = document.querySelector('input[name="data[key]"]') as HTMLInputElement;
  inputElement.value = value;
  inputElement.dispatchEvent(new Event('input'));
};

const applyActionToFieldSelectElement = (action: (element: HTMLFieldSelectElement) => void) => {
  const fieldSelectElement = getFieldSelectElement();

  if (fieldSelectElement) {
    action(fieldSelectElement);
  }
};

//===============================================
// event handlers
//===============================================

const inputEventHandler = () => {
  applyActionToFieldSelectElement((fieldSelectElement) => {
    const value = fieldSelectElement.value;
    const parsedFieldname = parseFieldName(value);

    setKeyElementValue(value);

    if (!parsedFieldname.isValid) {
      removeDropdownElement();
    } else if (parsedFieldname.path === '') {
      fieldSelectElement.data = getInitialData();
      createDropdownElement(fieldSelectElement, parsedFieldname.field);
    } else if (parsedFieldname.path !== fieldSelectElement.data.path) {
      const service = new OpenApiService();
      service
        .search(parsedFieldname.path)
        .then((fieldInfo) => {
          fieldSelectElement.data.path = parsedFieldname.path;
          fieldSelectElement.data.fieldNames = fieldInfo?.propertyNames ?? [];
          createDropdownElement(fieldSelectElement, parsedFieldname.field);
        })
        .catch(() => {
          // TODO: server returns 500 on unknown paths, ignore for now
          fieldSelectElement.data.path = parsedFieldname.path;
          fieldSelectElement.data.fieldNames = [];
        });
    } else {
      createDropdownElement(fieldSelectElement, parsedFieldname.field);
    }
  });
};

const keydownEventHandler = (e: KeyboardEvent) => {
  const dropdownElement = getDropdownElement();
  const items = dropdownElement ? dropdownElement.getElementsByTagName('div') : null;

  if (items) {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        highlightItem(items, Direction.next);
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        highlightItem(items, Direction.previous);
        break;
      }
      case 'Enter': {
        e.preventDefault();

        const array = Array.from(items);
        const currentFocus = array.findIndex((item) => item.classList.contains('active'));
        items[currentFocus]?.click();

        break;
      }
    }
  }
};

const documentClickEventHandler = (e: Event) => {
  applyActionToFieldSelectElement((fieldSelectElement) => {
    const dropdownElement = getDropdownElement();
    const target = e.target;

    if (dropdownElement && target !== dropdownElement && target !== fieldSelectElement) {
      removeDropdownElement();
    }
  });
};

const dropdownItemClickEventHandler = (e: Event) => {
  applyActionToFieldSelectElement((fieldSelectElement) => {
    const target = e.target as HTMLDivElement;
    const current = fieldSelectElement.value;

    fieldSelectElement.value =
      current.lastIndexOf('.') > 0
        ? current.substring(0, current.lastIndexOf('.')) + '.' + target.innerText
        : target.innerText;

    fieldSelectElement.dispatchEvent(new Event('input'));

    removeDropdownElement();
    fieldSelectElement.focus();
  });
};

//===============================================
// public variables
//===============================================

export const tryInitializeFieldSelectElement = () => {
  applyActionToFieldSelectElement((fieldSelectElement) => {
    // The fieldSelectComponent is initialized with the formio customDefaultValue event.
    // This event is called multiple times, on the first call the element is not created
    // and will be null. Initialized makes sure the event handlers are only added once
    // on the other calls.
    if (!fieldSelectElement.data.initialized) {
      fieldSelectElement.data.initialized = true;
      fieldSelectElement.addEventListener('input', inputEventHandler);
      fieldSelectElement.addEventListener('keydown', keydownEventHandler);
      document.addEventListener('click', documentClickEventHandler);
    }
  });
};
