
import { useTheme } from '@mui/material/styles';
function IconInsuranceCar3(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "38"}
            height={_props.height ? _props.height : "38"}
            viewBox="0 0 38 38"
        >
            <circle
                cx="19"
                cy="19"
                r="19"
                fill="rgba(0,70,255,.05)"
                data-name="Ellipse 467"
            ></circle>
            <path fill="none" d="M9 8.551h20v20H9z" data-name="Rectangle 2398"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.secondary.main}
                d="M19 11.051l-5 3.817v-1.317h-1.667v2.592l-2.5 1.908 1.008 1.325 1.492-1.142v7.817h13.333v-7.817l1.492 1.133 1.008-1.316zm5 13.333h-4.167v-3.333h-1.667v3.333H14v-7.416l5-3.817 5 3.817z"
                data-name="Path 3660"
            ></path>
        </svg>
    );
}

export default IconInsuranceCar3;
