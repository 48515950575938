//import { getScope } from 'context/Keycloak/Keycloak';

type Operation = 'view' | 'edit' | 'delete';

// https://roundcube.atlassian.net/wiki/spaces/RCIP/pages/3021897792/Scope+setup
//const SCOPE_DIP_CS_ADMIN = 'dip_cs_admin';
//const SCOPE_DIP_CS_RESTRICTED = 'dip_cs_restricted';
//const SCOPE_DIP_CS_DEFAULT = 'dip_cs_default';

// NOTE: These are the scopes defined in keycloak as "dip_cs_admin", "dip_restricted", "dip_cs_default"
// if they change, we need to change this too
/*const operationScopes: Record<Operation, string[]> = {
  view: [
    SCOPE_DIP_CS_ADMIN,
    SCOPE_DIP_CS_RESTRICTED,
    SCOPE_DIP_CS_DEFAULT
  ],
  edit: [SCOPE_DIP_CS_ADMIN, SCOPE_DIP_CS_RESTRICTED],
  delete: [SCOPE_DIP_CS_ADMIN]
};*/

export function canPerformOperation(operation: Operation): boolean {
  //const allowedScopes = operationScopes[operation];
  // const scope = getScope();
  // return allowedScopes.some((s) => scope.includes(s));
  return !!operation;
}
