export const fileuploadStyle = {
  display: 'flex',
  position: 'relative',
  border: 1,
  borderColor: '#f4f4f4',
  p: 2,
  mb: 2,
  backgroundColor: '#fafafa',
  borderRadius: '16px',
  '& .msgUploadStyle': {
    fontSize: 14,
    fontStyle: 'italic'
  },
  '& .inputStyle': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 100,
    opacity: 0
  }
};
