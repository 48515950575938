import { useTheme } from '@mui/material/styles';

function IconOffers(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "32"}
            height={_props.height ? _props.height : "32"}
            viewBox={`0 0 32 32`}
        >
            <path
                fill="none"
                stroke={_props.color ? _props.color : theme.palette.primary.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M23.495 14.382V5.699h-11.22"
                data-name="Path 3643"
            ></path>
            <path
                fill="none"
                stroke={_props.color ? _props.color : theme.palette.primary.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M23.56 16.947v9.264H8.35v-1.537"
                data-name="Path 3644"
            ></path>
            <path
                fill="none"
                stroke={_props.color ? _props.color : theme.palette.primary.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8.35 22.098V5.699h1.7"
                data-name="Path 3645"
            ></path>
            <path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 2386"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M17.677 18.431a2.792 2.792 0 00.778-.71l1.107.976a3.383 3.383 0 01-1.271 1.122 3.634 3.634 0 01-1.671.389 3.553 3.553 0 01-2.289-.789 3.775 3.775 0 01-1.305-2.152h-.881v-.782h.778c-.015-.183-.023-.319-.023-.4s.008-.21.023-.378h-.778v-.778h.881a3.759 3.759 0 011.3-2.163 3.618 3.618 0 015.237.71l-1.11.984a2.789 2.789 0 00-.776-.709 2.064 2.064 0 00-1.053-.241 1.751 1.751 0 00-1.116.4 2.183 2.183 0 00-.715 1.025h2.267v.778h-2.475a2.17 2.17 0 00-.031.377 2.427 2.427 0 00.034.4h2.473v.779h-2.27a2.221 2.221 0 00.727 1.02 1.751 1.751 0 001.1.39 2.085 2.085 0 001.059-.248z"
                data-name="Path 3646"
                style={{ isolation: "isolate" }}
            ></path>
        </svg>
    );
}

export default IconOffers;
