import { useTheme } from '@mui/material/styles';
function IconAttachment(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "38"}
            height={_props.height ? _props.height : "38"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3707"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M16.5 6v11.5a4 4 0 01-8 0V5a2.5 2.5 0 015 0v10.5a1 1 0 01-2 0V6H10v9.5a2.5 2.5 0 005 0V5a4 4 0 00-8 0v12.5a5.5 5.5 0 0011 0V6z"
                data-name="Path 3708"
            ></path>
        </svg>
    );
}

export default IconAttachment;