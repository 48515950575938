function IconRemove(_props: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={_props.width ? _props.width : '32'}
      height={_props.height ? _props.height : '32'}
      viewBox='0 0 24 24'>
      <circle
        cx='12'
        cy='12'
        r='12'
        fill={_props.color ? _props.color : 'rgba(0,0,90,.2)'}
        data-name='Ellipse 416'></circle>
      <g fill='none' stroke='#00005A' strokeLinecap='round' strokeWidth='2' data-name='Group 5458'>
        <path d='M8.491 8.251l7.5 7.5' data-name='Path 145'></path>
        <path d='M15.99 8.25l-7.5 7.5' data-name='Path 146'></path>
      </g>
    </svg>
  );
}

export default IconRemove;
