import { useState } from 'react';
import { Button, FormControl, Input } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { keyGenerator } from 'utils/Utils';
import { useTranslation } from 'react-i18next';
import { InputLable } from '../InputLabel/InputLabel';
import { IFileUploadProps } from './IFilePorps';
import { fileuploadStyle } from './FileuploadStyle';
import { useSnackBar } from 'context/Snackbar/Snackbar';

const randomKey = keyGenerator();

const FileUpload = (props: IFileUploadProps) => {
  const { t } = useTranslation<string>();
  const { showSnackBar } = useSnackBar();
  const [selectedFile, setSelectedFile] = useState<File[]>([]);

  const handleFileChange = (event: any) => {
    const files: File[] = Array.from(event.target.files);
    setSelectedFile([...files]);
    console.log(files);
  };

  const handleClick = () => {};
  const handleDelete = () => {};
  const handleUpload = () => {
    // Handle file upload logic here
    if (selectedFile) {
      // You can use FormData to send the file to your server
      const formData = new FormData();
      //formData.append('file', selectedFile);

      // Perform the file upload using fetch or your preferred HTTP library
      fetch('/upload-endpoint', {
        method: 'POST',
        body: formData
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response from the server
          console.log(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
    }
  };

  return (
    <div>
      <FormControl sx={{ typography: 'body2', mb: 2 }} fullWidth variant='standard'>
        <InputLable shrink htmlFor={`${randomKey}MuiInputfield`}>
          {props.label}
        </InputLable>
        <Box sx={fileuploadStyle}>
          <CloudUploadIcon sx={{ mr: 2 }}></CloudUploadIcon>
          <Box className='msgUploadStyle'>Drag your files here to upload them</Box>
          <Input type='file' onChange={handleFileChange} className='inputStyle' />
        </Box>
      </FormControl>
      <Button onClick={handleUpload} variant='contained' color='primary' className='hidden'>
        {t('upload')}
      </Button>
      <Stack direction='row' spacing={1}>
        {selectedFile.map((itm: File) => (
          <Chip label={itm.name} variant='outlined' onClick={handleClick} onDelete={handleDelete} />
        ))}
      </Stack>
    </div>
  );
};

export default FileUpload;
