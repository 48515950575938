import { useTheme } from '@mui/material/styles';

function IconAction(_props: any) {
  const theme = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={_props.width ? _props.width : '32'}
      height={_props.height ? _props.height : '32'}
      viewBox={`0 0 ${_props.width || '32'} ${_props.height || '32'}`}>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.primary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M13.409 10.252h12.533'
        data-name='Line 364'></path>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.primary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M13.409 16.693h12.533'
        data-name='Line 365'></path>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.primary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M13.409 23.134h12.533'
        data-name='Line 366'></path>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.primary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M5.968 9.821l1.159 1.288 2.49-3.091'
        data-name='Path 3629'></path>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.primary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M5.968 16.212l1.159 1.287 2.49-3.091'
        data-name='Path 3630'></path>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.primary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M5.968 22.604l1.159 1.288 2.49-3.091'
        data-name='Path 3631'></path>
      <path fill='none' d='M0 0h32v32H0z' data-name='Rectangle 2382'></path>
    </svg>
  );
}

export default IconAction;
