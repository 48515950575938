import axios from 'axios';
import config from 'config/config';

import { handleError } from './RCIPErrorHandler';
import { getToken, updateToken } from 'context/Keycloak/Keycloak';

type HttpMethod = 'delete' | 'get' | 'post' | 'put';

//===============================================
// private variables
//===============================================

const options = {
  baseURL: config.server_url,
  timeout: config.timeout
};

const create_instance = () => {
  const instance = axios.create(options);

  // add interceptor to set token
  instance.interceptors.request.use(
    (config) => {
      config.headers['Authorization'] = `Bearer ${getToken()}`;
      config.headers['Accept'] = 'application/json';
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

const call = (url: string, method: HttpMethod, item: any = undefined): Promise<any> => {
  const useKeycloak = getToken() !== undefined;
  const call_rcip = instance[method](url, item).then((response) => response.data);
  const promise = useKeycloak ? updateToken().then(() => call_rcip) : call_rcip;

  return promise.catch((error) => {
    throw handleError(error);
  });
};

const instance = create_instance();

//===============================================
// AxioServerProxy
//===============================================

export default class AxioServerProxy {
  static delete(url: string) {
    return call(url, 'delete');
  }

  static get(url: string) {
    return call(url, 'get');
  }

  static post(url: string, item: any) {
    return call(url, 'post', item);
  }

  static put(url: string, item: any) {
    return call(url, 'put', item);
  }
}
