export const backdrop = { color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 };
export const avatar = { bgcolor: (theme: any) => theme.palette.secondary.main, width: 70, height: 70 };
export const contentScroll = { mt: 1, mb: 3, height: 'calc(100vh - 180px)', overflowY: 'auto', overflowX: 'hidden' };
export const billingDetailsText = {
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};
export const moredetails = { color: (theme: any) => theme.palette.primary.main, ml: 2 };
export const editText = { color: (theme: any) => theme.palette.primary.main, ml: 2 };
export const BillingWrapper = {
  width: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const BillingName = {
  fontSize: '24px',
  color: (theme: any) => theme.palette.primary.main,
  fontWeight: 'bold',
  width: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
