
import { useTheme } from '@mui/material/styles';
function IconInsurance(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "32"}
            height={_props.height ? _props.height : "32"}
            viewBox="0 0 32 32"
        >
            <path
                fill="none"
                stroke={_props.color ? _props.color : theme.palette.primary.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11.348 22.4a10.875 10.875 0 004.607 3.263c4.787-1.794 6.781-6.069 7.6-9.707"
                data-name="Path 3639"
            ></path>
            <path
                fill="none"
                stroke={_props.color ? _props.color : theme.palette.primary.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M9.333 9.751c-.893.16-1.495.2-1.495.2s-.47 6.063 2.386 10.854"
                data-name="Path 3640"
            ></path>
            <path
                fill="none"
                stroke={_props.color ? _props.color : theme.palette.primary.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M23.904 13.961a21.561 21.561 0 00.168-4.005s-6.231-.463-8.116-3.708c-.968 1.665-3.08 2.6-4.909 3.111"
                data-name="Path 3641"
            ></path>
            <path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 2385"></path>
            <path
                fill="none"
                stroke={_props.color ? _props.color : theme.palette.primary.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M15.955 18.98l2.812-2.81a1.9 1.9 0 00-2.683-2.678l-.129.134-.139-.139a1.881 1.881 0 00-1.333-.556 1.845 1.845 0 00-1.333.556 1.874 1.874 0 00-.557 1.333 1.849 1.849 0 00.557 1.333l2.805 2.827"
                data-name="Path 3642"
            ></path>
        </svg>
    );
}

export default IconInsurance;