import { FormBuilder } from '@formio/react';
import { IForm } from '@ccs-dip/common/types/formio-types';
import FormRenderer from '../Formrenderer/FormRenderer';

const customComponents = {
  customForm: {
    title: 'Add Existing Form',
    icon: 'terminal',
    schema: {
      label: 'Custom Form',
      type: 'DUMMY_FORM',
      key: 'DUMMY_FORM',
      input: true,
      noNewEdit: true
    }
  },
  customField: {
    title: 'Add API Field',
    icon: 'cog',
    schema: {
      label: 'Custom Field',
      type: 'DUMMY_FIELD',
      key: 'DUMMY_FIELD',
      input: true,
      noNewEdit: true
    }
  }
};

export interface FormBuilderWrapperProps {
  form: IForm;
  onChange: (schema: IForm) => void;
  options: {
    noAlerts: boolean;
    builder?: Record<string, object>;
  };
  isPreviewMode: boolean;
}
const FormBuilderWrapper = (props: FormBuilderWrapperProps) => {
  const options = {
    ...props.options,
    noDefaultSubmitButton: true,
    builder: {
      ...props.options.builder,
      custom: {
        title: 'Custom Components',
        weight: 10,
        noNewEdit: true,
        components: customComponents
      }
    }
  };
  return props.isPreviewMode ? (
    <FormRenderer formsrc={props.form.key}/>
  ) : (
    <FormBuilder {...props} options={options} />
  );
};

export default FormBuilderWrapper;
