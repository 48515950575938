import Server from 'entities/RCIPServerProxy';
import { ISearchResult } from './ISearchResult';

type SearchDomain = 'All' | 'Claim' | 'Offer' | 'Party' | 'Policy' | 'WPolicy';

//==============================================================
// private variables
//==============================================================

const en_to_nl = (domain: SearchDomain) => {
  switch (domain) {
    case 'All':
      return '';
    case 'Claim':
      return 'Schade';
    case 'Offer':
      return 'Offerte';
    case 'Policy':
      return 'Polis';
    case 'WPolicy':
      return 'WPolis';
    case 'Party':
      return 'Relatie';
  }
};

const nl_to_en = (domain: string) => {
  switch (domain) {
    case 'Schade':
      return 'Claim';
    case 'Offerte':
      return 'Offer';
    case 'Polis':
      return 'Policy';
    case 'Relatie':
      return 'Party';
    case 'WPolis':
      return 'WPolicy';
    default:
      return domain;
  }
};

//==============================================================
// Summary EntityService
//==============================================================

export class SearchService {
  search(query: string, domain: SearchDomain = 'All', mci: boolean = false, ssai: boolean = false) {
    const domain_nl = en_to_nl(domain);

    return Server.get(`/utility/v2/search?q=${query}&domain=${domain_nl}&mci=${mci}&ssai=${ssai}`).then((data) => {
      const list: Array<ISearchResult> = data?.SearchResult ?? [];

      list.forEach((result) => {
        result.Domain = nl_to_en(result.Domain);
      });

      return list;
    });
  }
}

export default SearchService;
