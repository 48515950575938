

function IconCalender(_props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <g data-name="Group 7045">
                <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2437"></path>
            </g>
            <g data-name="Group 7046">
                <path
                    fill={_props.color ? _props.color : "#00005a"}
                    d="M19 4h-1V2h-2v2H8V2H6v2H5a1.991 1.991 0 00-1.99 2L3 20a2 2 0 002 2h14a2.006 2.006 0 002-2V6a2.006 2.006 0 00-2-2zm0 16H5V10h14zm0-12H5V6h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
                    data-name="Path 3732"
                ></path>
            </g>
        </svg>
    );
}

export default IconCalender;