import { useTheme } from '@mui/material/styles';
function IconLargePlus(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "32"}
            height={_props.height ? _props.height : "32"}
            viewBox="0 0 32 32"
        >
            <circle
                cx="16"
                cy="16"
                r="16"
                fill={_props.color ? _props.color : theme.palette.secondary.main}
                data-name="Ellipse 416"
                transform="rotate(-90 16 16)"
            ></circle>
            <path
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
                d="M11.279 15.774l9.895.004"
                data-name="Path 145"
            ></path>
            <path
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
                d="M16.226 10.826v9.9"
                data-name="Path 146"
            ></path>
        </svg>
    );
}

export default IconLargePlus;