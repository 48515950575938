
import { useTheme } from '@mui/material/styles';
function IconDoccomplete(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <g data-name="Group 7036">
                <path fill="none" d="M0 0h24v24H0z" data-name="Path 3725"></path>
            </g>
            <g data-name="Group 7037">
                <path
                    fill={_props.color ? _props.color : theme.palette.primary.main}
                    d="M14 2H6a2 2 0 00-1.99 2L4 20a2 2 0 001.99 2H18a2.006 2.006 0 002-2V8zm4 18H6V4h7v5h5zm-9.18-6.95L7.4 14.46 10.94 18l5.66-5.66-1.41-1.41-4.24 4.24z"
                    data-name="Path 3726"
                ></path>
            </g>
        </svg>
    );
}

export default IconDoccomplete;