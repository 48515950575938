export const backdrop = { color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 };
export const avatar = { bgcolor: (theme: any) => theme.palette.secondary.main, width: 70, height: 70 };
export const wrapper = { width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
export const attachFileName = {
  fontSize: '24px',
  color: (theme: any) => theme.palette.primary.main,
  fontWeight: 'bold'
};
export const archiveNumber = { fontWeight: 800, fontSize: '14px', color: (theme: any) => theme.palette.primary.main };
export const archiveDetailsText = {
  mt: 1,
  mb: 3,
  height: 'calc(100vh - 220px)',
  overflowY: 'auto',
  overflowX: 'hidden'
};
export const iconButton = { paddingRight: '10px', display: 'inline-block' };
export const attachFilenameWrapper = {
  width: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const atachFilename = {
  fontSize: '24px',
  color: (theme: any) => theme.palette.primary.main,
  fontWeight: 'bold',
  width: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const archiveNumberText = {
  fontWeight: 800,
  fontSize: '14px',
  color: (theme: any) => theme.palette.primary.main
};
export const archiveText = {
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};
