import EntityService from 'entities/EntityService';
import { IParty } from './IParty';

type PartySearchFilter = 'EmailAddr' | 'Name' | 'PostalCode';

//==============================================================
// Party EntityService
//==============================================================

export class PartyService extends EntityService<IParty, PartySearchFilter> {
  protected flat(data: any) {
    return data?.Party ?? [];
  }

  protected getId(item: IParty) {
    return item.PartyNumber;
  }

  protected getPath() {
    return '/party/v4/parties';
  }
}

export type { PartySearchFilter };
export default PartyService;
