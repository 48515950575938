import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import useFormioData, { FormioRow } from './useFormioData';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import FormioService from 'entities/Formio/FormioService';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import { useNavigate } from 'react-router-dom';
import UploadFormJson from './FormJsonUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ChangeEvent, useEffect, useState } from 'react';
import { IForm } from '@ccs-dip/common/types/formio-types';
import { downloadFormAsJson } from 'utils/Utils';
import { FormButtonProps, FormioDataGridProps } from './IFormDataGrid';
import {
  addButton,
  descriptionIcon,
  displayForm,
  formButtonWrap,
  iconWrapper,
  iconWrapperDisabled,
  labelIcon,
  launchButton,
  titleCell,
  titleText,
  toolBar,
  toolbarWrap
} from './FormGridStyledComponent';
import FormGridIcons from './FormDataGridIcons';
import { setBackgroundColor } from '../FormBuilder/FormSaveButtonStyles';
import CustomDataGridToolbar, { DataGridProSx } from 'utils/CustomDataGridToolbar';

const FormioDataGrid: React.FC<FormioDataGridProps> = ({ handleRowClick, handleNewForm }) => {
  const [file, setFile] = useState<File | null>(null);
  const [open, setOpen] = useState(false);
  const [resolveDialog, setResolveDialog] = useState<((value: boolean) => void) | null>(null);
  const [dialogContent, setDialogContent] = useState('');
  const [isDialogDelete, setDialogDelete] = useState<boolean>(false);
  const { rows, deleteFormio, fetchFormio } = useFormioData();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const formioService = new FormioService();

  const clearFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target) {
      event.target.value = '';
    }
  };
  const handleDeleteFormio = async (key: string) => {
    const confirmed = await openDeleteDialog();
    if (confirmed) {
      deleteFormio(key);
    } else {
      showSnackBar(t('unableDelete'), 'error');
    }
  };
  const openImportDialog = () => {
    return new Promise<boolean>((resolve) => {
      setOpen(true);
      setResolveDialog(() => resolve);
      setDialogContent(t('formExist'));
      setDialogDelete(false);
    });
  };

  useEffect(() => {
    const savedPagination = localStorage.getItem('dataGridPagination');
    if (savedPagination) {
      setPaginationModel(JSON.parse(savedPagination));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('dataGridPagination', JSON.stringify(paginationModel));
  }, [paginationModel]);

  const openDeleteDialog = () => {
    return new Promise<boolean>((resolve) => {
      setOpen(true);
      setResolveDialog(() => resolve);
      setDialogContent(t('formDelete'));
      setDialogDelete(true);
    });
  };

  const handleClose = (value: boolean) => {
    setOpen(false);
    if (resolveDialog) {
      resolveDialog(value);
    }
  };
  const isFormValid = (data: IForm): boolean => {
    // Check if at least one of the required fields exists
    if (!data.id && !data.name && !data.key && !data.components?.length) {
      return false;
    }

    // Ensure components is always an array
    data.components = data.components || [];

    // Set id and key, prioritizing existing values
    data.id = data._id || data.name || data.key || data.id || '';
    data.key = data.name || data.id;

    return true;
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (!selectedFile) {
      showSnackBar(t('noFileSelected'), 'error');
      return;
    }
    const fileSize = selectedFile.size;
    const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);
    const maxSizeInBytes = 10 * 1024 * 1024;
    if (parseFloat(fileSizeInMB) > maxSizeInBytes) {
      showSnackBar(t('fileSizeError'), 'error');
    }
    if (!/.json/gi.test(selectedFile.name)) {
      showSnackBar(t('jsonFormat'), 'error');
      return;
    }
    setFile(selectedFile);

    try {
      const formData = await readFileAsJson<IForm>(selectedFile);

      if (!isFormValid(formData)) {
        showSnackBar(t('formError'), 'error');
        return;
      }
      const form: IForm = await formioService.get(formData.key);
      if (Object.keys(form).length) {
        const confirmed = await openImportDialog();
        if (confirmed) {
          // Perform save operation
          await formioService.save(formData.key, formData);
          clearFile(event);
          fetchFormio();
          showSnackBar(t('uploadMessage'), 'success');
        } else {
          clearFile(event);
          showSnackBar(t('uploadError'), 'error');
        }
      } else {
        await formioService.save(formData.key, formData);
        clearFile(event);
        fetchFormio();
        showSnackBar(t('uploadMessage'), 'success');
      }
    } catch (error) {
      console.error('File upload error:', error);
      clearFile(event);
      showSnackBar(t('uploadError'), 'error');
    }
  };

  const readFileAsJson = <T,>(file: File): Promise<T> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const data = JSON.parse(e.target?.result as string);
          resolve(data as T);
        } catch (error) {
          reject(new Error('Invalid JSON format'));
        }
      };
      reader.onerror = () => reject(new Error('File reading failed'));
      reader.readAsText(file);
    });
  };

  const exportAsJson = async (key: string): Promise<void> => {
    try {
      const form: IForm = await formioService.get(key);
      downloadFormAsJson(form);
    } catch (error) {
      console.error('Error exporting form:', error);
    }
  };

  const renderTitleCell = (params: any) => (
    <>
      <FormGridIcons.SourceIcon sx={titleCell} />
      <Box title={params.row.title} sx={titleText}>
        {params.row.title}
      </Box>
    </>
  );

  const renderNameCell = (params: any) => (
    <>
      <FormGridIcons.DriveFileRenameOutlineOutlinedIcon sx={titleCell} />
      <Box title={params.row.name} sx={titleText}>
        {params.row.name}
      </Box>
    </>
  );

  const renderDisplayCell = (params: any) => (
    <>
      {params.row.display === 'wizard' ? (
        <FormGridIcons.VideoLabelIcon sx={labelIcon} />
      ) : (
        <FormGridIcons.DescriptionOutlinedIcon sx={descriptionIcon} />
      )}
      <Typography component='span' sx={displayForm}>
        {t(params.row.display) || t('form')}
      </Typography>
    </>
  );

  const renderSavedOption = (params: any) => {
    return (
      <>
        {setBackgroundColor(params.row.savedOption).icon}
        <Typography component='span' sx={displayForm}>
          {t(params.row.savedOption || 'Draft')}
        </Typography>
      </>
    );
  };

  const renderActionCell = (params: any, handleLaunch: Function, exportAsJson: Function) => (
    <>
      <Button sx={launchButton} onClick={() => handleLaunch(params.row.key)} variant='contained'>
        <FormGridIcons.LaunchIcon sx={{ color: '#fff', mr: 1, fontSize: '16px' }} />
        {t('launch')}
      </Button>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          exportAsJson(params.row.key);
        }}
        color='primary'
        aria-label='download'>
        <Tooltip title={t('export')}>
          <FormGridIcons.FileDownloadIcon sx={iconWrapper} />
        </Tooltip>
      </IconButton>
      {params.row.savedOption !== 'Publish' ? (
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteFormio(params.row.key);
          }}
          disabled={params.row.savedOption === 'Publish' ? true : false}
          color='primary'
          aria-label='delete'>
          <Tooltip title={t('delete')}>
            <FormGridIcons.DeleteOutlineIcon
              sx={params.row.savedOption === 'Publish' ? iconWrapperDisabled : iconWrapper}
            />
          </Tooltip>
        </IconButton>
      ) : (
        ''
      )}
    </>
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      renderCell: renderNameCell
    },
    {
      field: 'title',
      headerName: t('title'),
      flex: 1,
      sortable: true,
      renderCell: renderTitleCell
    },

    {
      field: 'display',
      headerName: t('display'),
      flex: 1,
      sortable: true,
      renderCell: renderDisplayCell
    },
    {
      field: 'savedOption',
      headerName: t('version'),
      sortable: true,
      flex: 1,
      renderCell: renderSavedOption
    },
    {
      field: 'delete',
      headerName: t('action'),
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => renderActionCell(params, handleLaunch, exportAsJson)
    }
  ];
  const handleLaunch = (key: string) => {
    navigate(`/formrenderer/${key}`);
  };

  const FormButton: React.FC<FormButtonProps> = ({ onClick, icon, label }) => (
    <Button variant='contained' sx={formButtonWrap} onClick={onClick}>
      {icon}
      {label}
    </Button>
  );

  return (
    <Box sx={{ py: 2 }}>
      <DataGridPro
        sx={DataGridProSx}
        rows={rows}
        columns={columns}
        columnHeaderHeight={36}
        editMode='row'
        getRowId={(row: FormioRow) => row.key}
        disableColumnPinning
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'key', sort: 'asc' }]
          },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        onRowClick={(e) => {
          handleRowClick(e);
        }}
        pageSizeOptions={[
          10,
          20,
          40
        ]}
        slots={{ toolbar: CustomDataGridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            title: t('formdatagrid')
          }
        }}
      />

      <Box sx={toolBar}>
        <Box sx={toolbarWrap}>
          <UploadFormJson file={file} handleFileUpload={handleFileUpload} />
          <FormButton
            onClick={() => handleNewForm('newForm')}
            icon={<FormGridIcons.AddCircleOutlineIcon sx={addButton} />}
            label={t('newForm')}
          />
          <FormButton
            onClick={() => handleNewForm('newWizard')}
            icon={<FormGridIcons.AddToPhotosOutlinedIcon sx={addButton} />}
            label={t('newWizard')}
          />
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Confirmation'}</DialogTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}> {t('cancel')}</Button>
          <Button onClick={() => handleClose(true)} variant='contained' autoFocus>
            {isDialogDelete ? t('delete') : t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FormioDataGrid;
