import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { keyGenerator } from '../../../utils/Utils';
import { countries } from 'utils/Constants';
import { useTranslation } from 'react-i18next';
import { InputLable } from '../InputLabel/InputLabel';
import { boxWrapper, chipWrapper, inputLabelStyle } from './SearchandAddTagStyles';
import { IsearchandAddTag } from './ISearchandAddtagType';
const randomKey = keyGenerator();

export function SearchandAddtag(props: IsearchandAddTag) {
  const [chipval, setChipVal] = useState<any>([]);
  const { t } = useTranslation<string>();
  const handleDelete = () => {};
  const handleAddTag = (value: any) => {
    const temp = [...chipval, value];
    setChipVal(temp);
  };

  return (
    <Autocomplete
      id='autocomplete'
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.phone}
      renderOption={(props, option) => (
        <Box {...props} key={keyGenerator()} component='li' sx={boxWrapper}>
          <img
            loading='lazy'
            width='20'
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=''
          />
          ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <Box>
          <Box sx={{ display: 'inline-flex', width: '100%' }}>
            <FormControl
              sx={{
                '.MuiFormControl-root .MuiOutlinedInput-root': { pr: 2 },
                '.MuiFormControl-root  .MuiInputBase-root.MuiOutlinedInput-root': { p: '10px' }
              }}
              fullWidth
              variant='standard'>
              <InputLable shrink htmlFor={`${randomKey}MuiInputfield`} sx={inputLabelStyle}>
                {t(props.label)}
              </InputLable>
              <TextField
                {...params}
                size='small'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon className='IconStyle'></SearchIcon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <AddCircleIcon
                        onClick={() => {
                          handleAddTag(params.inputProps.value);
                        }}
                        className='IconStyle'
                      />
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Box>
          <Box sx={chipWrapper}>
            <Stack direction='row' spacing={1}>
              {chipval.length
                ? chipval?.map((_itm: any) => <Chip label={_itm} variant='outlined' onDelete={handleDelete} />)
                : ''}
            </Stack>
          </Box>
        </Box>
      )}
    />
  );
}

export default SearchandAddtag;
