
import { useTheme } from '@mui/material/styles';
function IconHouse(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "20"}
            height={_props.height ? _props.height : "20"}
            viewBox="0 0 20 20"
        >
            <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 2398"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.secondary.main}
                d="M10 2.5L5 6.317V5H3.333v2.592L.833 9.5l1.008 1.325 1.492-1.142V17.5h13.333V9.683l1.492 1.133L19.166 9.5zm5 13.333h-4.167V12.5H9.166v3.333H5V8.417L10 4.6l5 3.817z"
                data-name="Path 3660"
            ></path>
        </svg>
    );
}

export default IconHouse;