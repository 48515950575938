import IEntityService from './IEntityService';
import Server from 'entities/RCIPServerProxy';
import RCIPError from './RCIPError';

export default abstract class EntityService<T, F> implements IEntityService<T, F> {
  //===============================================
  // IEntityService members
  //===============================================

  delete(id: string): Promise<T> {
    const path = this.getPath();
    return Server.delete(`${path}/${id}`).catch((error: any) => {
      return this.errorHandler(error, id, null);
    });
  }

  get(id: string): Promise<T> {
    const path = this.getPath();
    return Server.get(`${path}/${id}`).catch((error) => {
      return this.errorHandler(error, id, null);
    }); 
  }

  getAll(): Promise<T[]> {
    const path = this.getPath();
    return Server.get(path).then(this.flat);
  }

  save(item: T): Promise<T> {
    const id = this.getId(item);
    const path = this.getPath();
    const update = !!id;

    return (update ? Server.put(`${path}/${id}`, item) : Server.post(path, item)).catch((error) => {
      return this.errorHandler(error, id, item);
    });
  }

  search(filter: F, value: string): Promise<T[]> {
    const path = this.getPath();
    return Server.get(`${path}?${filter}=${value}`).then(this.flat);
  }

  //===============================================
  // protected members
  //===============================================

  protected abstract flat(data: any): T[];
  protected abstract getId(item: T): string;
  protected abstract getPath(): string;

  protected errorHandler(error: RCIPError, id: string, _item: T | null): RCIPError {
    if (error.code === '2209' && id) {
      error.message = `${id} cannot be deleted. Child records found.`;
    }
    return error;
  }
}
