import EntityService from 'entities/EntityService';
import { IClaim } from './IClaim';

type ClaimSearchFilter = 'PartyNumber';

//==============================================================
// Claim EntityService
//==============================================================

export class ClaimService extends EntityService<IClaim, ClaimSearchFilter> {
  protected flat(data: any) {
    return (
      data?.Claim.map((itm: any) => {
        const PolicyNumber = itm.Policy.CompanysPolicyNumber;
        return { ...itm.ClaimsOccurrence, PolicyNumber };
      }) ?? []
    );
  }

  protected getId(item: IClaim) {
    return item.ClaimsOccurrence.ClaimNumber;
  }

  protected getPath() {
    return '/claim/v4/claims';
  }
}

export type { ClaimSearchFilter };
export default ClaimService;
