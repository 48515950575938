export const accountMenuCancelStyle = {
  elevation: 0,
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    backgroundColor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0
  },
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'black',
  borderRadius: '20px',
  '&:hover': {
    color: 'blue',
    bgcolor: '#f0f8ff'
  },
  '& .IconButtonStyle': {
    borderRadius: '50%',
    backgroundColor: '#f8f8f8',
    padding: '4px'
  }
};
