
import { useTheme } from '@mui/material/styles';
function IconPerson(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "18"}
            height={_props.height ? _props.height : "18"}
            viewBox="0 0 21.449 21.449"
        >
            <path fill="none" d="M0 0h21.449v21.449H0z" data-name="Path 3647"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.secondary.main}
                d="M10.725 5.362A1.787 1.787 0 118.937 7.15a1.793 1.793 0 011.788-1.788m0 8.937c2.413 0 5.183 1.153 5.362 1.787H5.362c.206-.643 2.958-1.787 5.362-1.787m.001-10.724A3.575 3.575 0 1014.3 7.15a3.574 3.574 0 00-3.575-3.575zm0 8.937c-2.386 0-7.15 1.2-7.15 3.575v1.788h14.3v-1.788c0-2.377-4.764-3.575-7.15-3.575z"
                data-name="Path 3648"
            ></path>
        </svg>
    );
}

export default IconPerson;
