import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';

export const MuiTableheadercell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  border: '0px'
}));

export const NoDataFound = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  flexGrow: 1
}));

export const boxTitle = { px: 2, py: 1, fontWeight: 'bold', color: '#00005a', minWidth: '199px', fontSize: '14px' };
export const boxDesscription = { px: 2, py: 1, color: '#00005a', fontSize: '14px' };
export const policyWrapper = { width: '100%' };
export const policyInnerWrapper = { display: 'flex', p: 5 };
export const searchBarWrapper = { flexGrow: 1 };
export const filterWrapper = { minWidth: 200, ml: 2 };
export const SearchButtonWrapper = { minWidth: 200, ml: 2 };
export const ProfileHeader = { p: 3, background: '#f2f2f2', position: 'relative', width: '35%', boxShadow: 3 };
export const partyDetailsHeader = { fontSize: '16px', p: 2, color: '#00005a', fontWeight: 'bold' };
export const policyDetails = { display: 'flex' };
export const closeIconWrapper = { position: 'absolute', right: 0, top: 2 };
export const tableWrapper = {
  minWidth: 650,
  borderCollapse: 'separate',
  borderSpacing: '0 5px',
  background: '#fff',
  [`& .${tableCellClasses.root}`]: {
    borderBottom: 'none'
  }
};

export const tablecellWrapper = { background: 'transparent' };
export const highlightContent = { fontWeight: 'bold' };
export const tableRowWrapper = { background: 'white', fontWeight: 'bold!important' };
export const tablePartyRowWrapper = {
  '&:last-child td, &:last-child th': { border: 0 },
  backgroundColor: '#edf0fa',
  mb: 1
};

export const backdrop = { color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 };
export const avatar = { bgcolor: (theme: any) => theme.palette.secondary.main, width: 70, height: 70 };
export const wrapper = { width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
export const prefferName = { fontSize: '24px', color: (theme: any) => theme.palette.primary.main, fontWeight: 'bold' };
export const partyNumberText = { fontWeight: 800, fontSize: '14px', color: (theme: any) => theme.palette.primary.main };
export const contentWarp = { mt: 3, mb: 3, height: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'hidden' };
export const addressDetails = { fontWeight: 800, fontSize: '16px', color: (theme: any) => theme.palette.primary.main };
export const commDetails = { fontWeight: 800, fontSize: '16px', color: (theme: any) => theme.palette.primary.main };
export const paymentDetails = { fontWeight: 800, fontSize: '16px', color: (theme: any) => theme.palette.primary.main };
export const moredetails = { color: (theme: any) => theme.palette.primary.main, ml: 2 };
export const editDetails = { color: (theme: any) => theme.palette.primary.main, ml: 2 };
export const headerTitle = {
  fontSize: '24px',
  color: (theme: any) => theme.palette.primary.main,
  fontWeight: 'bold',
  width: '300px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const headerTitleWrapper = {
  width: '300px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const partyDetailsWrap = {
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};
