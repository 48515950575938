import { useTheme } from '@mui/material/styles';

function IconUparrow(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3676"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M9 6a1 1 0 001 1h5.59L4.7 17.89a1 1 0 001.41 1.41L17 8.41V14a1 1 0 002 0V6a1 1 0 00-1-1h-8a1 1 0 00-1 1z"
                data-name="Path 3677"
            ></path>
        </svg>
    );
}

export default IconUparrow;