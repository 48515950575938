import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import TranslationService, { LanguageCode, NamespaceCode } from 'entities/Translation/TranslationService';

//===============================================
// local variables
//===============================================

const defaultLang: LanguageCode = 'nl';
const defaultNS: NamespaceCode = 'custom';
const fallbackNS: NamespaceCode = 'default';

const getResources = (lang: LanguageCode, ns: NamespaceCode) => {
  const service = new TranslationService();
  return service.get(lang, ns);
};

const convertDetectedLanguage = (lng: string) => {
  const code = lng.slice(0, 2);
  const isSupported = code === 'nl' || code === 'en';
  return isSupported ? code : defaultLang;
};

//===============================================
// initialize i18next
//===============================================

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(resourcesToBackend(getResources))
  .init({
    defaultNS,
    fallbackNS,
    load: 'languageOnly',
    ns: TranslationService.namespaces,
    supportedLngs: TranslationService.languages,
    detection: {
      convertDetectedLanguage
    },
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
