import { useEffect, useState } from 'react';
import FormioService from 'entities/Formio/FormioService';
import { Alert, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IFormInfo } from '@ccs-dip/common/types/formio-types';

const FormBuilderForms: React.FC = () => {
  const [formio, setFormio] = useState<IFormInfo[]>([]);
  const service = new FormioService();
  const { t } = useTranslation<string>();

  useEffect(() => {
    service.getAllForm().then((forms: IFormInfo[]) => {
      setFormio(forms.filter((item) => item.savedOption === 'Publish'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ p: 5 }}>
        <h2>Ep Portal Forms</h2>
        {formio.length ? (
          formio.map((form: IFormInfo, index: number) => (
            <Box key={index} sx={{ p: 1 }}>
              <Link to={`/formrenderer/${form.key}`}>{form.title}</Link>
            </Box>
          ))
        ) : (
          <Box sx={{ py: 2 }}>
            <Alert severity='info'>{t('noFormsAvailable')}</Alert>
          </Box>
        )}
      </Box>
    </>
  );
};

export default FormBuilderForms;
