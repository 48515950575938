import {  useEffect, useState } from 'react';
import { i18n } from 'i18next';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import TranslationService, { LanguageCode, NamespaceCode } from 'entities/Translation/TranslationService';
import { TranslationRow, TranslationRowMap } from './TranslationRowMap';
import { ITranslationDataApi } from './ITranslationDataApi';
import { useLoader } from 'context/Loader/Loader';
import { useTranslation } from 'react-i18next';

//===============================================
// local variables
//===============================================

const custom_ns: NamespaceCode = 'custom';

const create_promisses = () => {
  const service = new TranslationService();
  const languages = TranslationService.languages;
  const ns = TranslationService.namespaces;

  return languages
    .map((lang) => ns.map((ns) => ({ lang, ns })))
    .flat()
    .map(({ lang, ns }) => service.get(lang, ns).then((translations) => ({ lang, ns, translations })));
};

const create_translation_row_map = () => {
  const promises = create_promisses();

  return Promise.all(promises).then((items) =>
    items.reduce((map, { lang, ns, translations }) => {
      const keys = Object.keys(translations);

      keys.forEach((key) => {
        const value = translations[key];
        map.create(lang, key, value, ns);
      });

      return map;
    }, new TranslationRowMap())
  );
};

const set_i18n = (i18n: i18n, lang: LanguageCode, key: string, value: string) => {
  if (i18n.hasResourceBundle(lang, custom_ns)) {
    i18n.addResource(lang, custom_ns, key, value);
    force_rerender(i18n);
  }
};

const reload_i18n = (i18n: i18n, lang: LanguageCode) => {
  if (i18n.hasResourceBundle(lang, custom_ns)) {
    i18n.removeResourceBundle(lang, custom_ns);
    i18n.reloadResources(lang, custom_ns, () => {
      force_rerender(i18n);
    });
  }
};

const force_rerender = (i18n: i18n) => {
  // change the language to the current seems
  // the only way to re-render the application
  i18n.changeLanguage(i18n.language);
};


//===============================================
// hook definition
//===============================================

const useTranslationData = () => {
  const [map, setMap] = useState<TranslationRowMap>(new TranslationRowMap());
  const [rows, setRows] = useState<TranslationRow[]>([]);
  const { i18n } = useTranslation();
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const service = new TranslationService();

  const api = {
    delete(lang: LanguageCode, key: string) {
      if(key==='$$empty'){
        return Promise.resolve().then(() => {
          setRows(map.getRows());
          reload_i18n(i18n, lang);
        })
      }else{
        return service.delete(lang, key).then(() => {
          setRows(map.getRows());
          map.delete(lang, key);
          reload_i18n(i18n, lang);
        });
      }
    },
    save(lang: LanguageCode, key: string, value: string) {
      return service.save(lang, key, value).then(() => {
        map.save(lang, key, value);
        set_i18n(i18n, lang, key, value);
        setRows(map.getRows());
      });
    },
    getGrid(){
      loader(
        create_translation_row_map()
          .then((map: TranslationRowMap) => {
            setMap(map);
            setRows(map.getRows());
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
          })
      );
    },
   addRow(id:string){
      setRows((oldRows:any) => [...oldRows,  {id,key:'',nl:{custom:''},en:{custom:''}}]);
    },
  } as ITranslationDataApi;

  useEffect(() => {
    loader(
      create_translation_row_map()
        .then((map: TranslationRowMap) => {
          setMap(map);
          setRows(map.getRows());
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { rows, api };
};

export default useTranslationData;
