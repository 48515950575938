import { useTheme } from '@mui/material/styles';

function IconPassport(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2435"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M13.17 4L18 8.83V20H6V4h7.17M14 2H6a2.006 2.006 0 00-2 2v16a2.006 2.006 0 002 2h12a2.006 2.006 0 002-2V8l-6-6zm-2 12a2 2 0 10-2-2 2.006 2.006 0 002 2zm4 3.43a2.011 2.011 0 00-1.22-1.85 6.952 6.952 0 00-5.56 0A2.011 2.011 0 008 17.43V18h8z"
                data-name="Path 3730"
            ></path>
        </svg>
    );
}

export default IconPassport;