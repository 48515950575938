//import { alpha } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
//import { grey } from '@mui/material/colors';
//import useTheme from '@mui/material/styles/useTheme';

/*const commonStyles = {
  borderColor: 'text.primary',
  m: 1,
  border: 0,
  width: '5rem',
  height: '5rem'
};*/

export function Palletes() {
  //const theme = useTheme();
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'start', px: 3, py: 5, background: '#f2f5ff' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <Typography component={'span'} sx={{ p: 2, fontSize: 24 }} variant='h1'>
            Page Title h1 Open sans bold -24pt
          </Typography>
          <Typography component={'span'} sx={{ p: 2 }} variant='h2'>
            Card Title h2 Open sans bold -18pt
          </Typography>
          <Typography component={'span'} sx={{ p: 2 }} variant='subtitle2' display='block' gutterBottom>
            P small Open Sans Bold - 14pt
          </Typography>
          <Typography component={'span'} sx={{ p: 2 }} variant='body2' display='block' gutterBottom>
            P small Open Sans Regular - 14pt
          </Typography>
          <Typography component={'span'} sx={{ p: 2 }} variant='caption' display='block' gutterBottom>
            P small Open Sans Regular - 12pt
          </Typography>
          <Typography component={'span'} sx={{ p: 2 }} variant='overline' display='block' gutterBottom>
            label open sans semibold - 10pt
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', pr: 3, flexDirection: 'column' }}></Box>
        </Box>
      </Box>
    </>
  );
}
