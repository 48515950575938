import { LanguageCode, NamespaceCode } from 'entities/Translation/TranslationService';

//===============================================
// type definitions
//===============================================

type TranslationRow = {
  key: string;
  isNew?:boolean;
  id?:string;
} & {
  [lang in LanguageCode]?: {
    [ns in NamespaceCode]?: string;
  };
};

type InnerMap = Map<string, TranslationRow>;

//===============================================
// local variables
//===============================================

const custom_ns: NamespaceCode = 'custom';

const setValue = (map: InnerMap, lang: LanguageCode, ns: NamespaceCode, key: string, value: string | undefined) => {
  if (value === undefined) {
    map.delete(key);
  } else {
    const row = map.get(key) ?? { key };
    const prop = row[lang];
    
    if (prop) {
      prop[ns] = value;
    } else {
      row[lang] = { [ns]: value };
    }
    
    map.set(key, row);
  }
};

//===============================================
// class definitions
//===============================================

class TranslationRowMap {
  private map = new Map<string, TranslationRow>();

  // only for initial loading of items
  // allows adding items for both namespaces
  create(lang: LanguageCode, key: string, value: string | undefined, ns = custom_ns) {
    setValue(this.map, lang, ns, key, value);
  }

  delete(lang: LanguageCode, key: string) {
    setValue(this.map, lang, custom_ns, key, undefined);
  }

  getRows() {
    const rows = this.map.values();
    return [...rows];
  }

  save(lang: LanguageCode, key: string, value: string) {
    setValue(this.map, lang, custom_ns, key, value);
  }
}

//===============================================
// exports
//===============================================

export type { TranslationRow };
export { TranslationRowMap };
