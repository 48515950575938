import { useTheme } from '@mui/material/styles';
import { OutlinedFormInput } from 'component/atoms/OutlinedInput/OutlinedInput';
import { MenuProps, names } from '../../../utils/Constants';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getStyles, keyGenerator } from 'utils/Utils';
import { useTranslation } from 'react-i18next';
import { IDropdownpickerProps } from './IDropdownpickerProps';
import { InputLable } from '../InputLabel/InputLabel';
import { dropDownStyle } from './DropDownStyle';

const randomKey = keyGenerator();
export const Dropdownpicker = (_props: IDropdownpickerProps) => {
  const { t } = useTranslation<string>();

  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string>('');

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value }
    } = event;
    setPersonName(value);
  };
  const customMenuOptions = _props.customMenuOptions || names || [];

  return (
    <div>
      <FormControl sx={{ width: 'auto', mb: 3 }}>
        {_props.label ? (
          <InputLable shrink htmlFor={`${randomKey}MuiInputfield`} sx={{ typography: 'body2' }}>
            {t(_props.label)}
          </InputLable>
        ) : (
          ''
        )}
        <Select
          id='DropdownPicker'
          IconComponent={KeyboardArrowDownIcon}
          displayEmpty
          value={_props.value || personName}
          onChange={_props.handleChange || handleChange}
          input={<OutlinedFormInput />}
          sx={dropDownStyle}
          MenuProps={MenuProps}
          inputProps={{
            'aria-label': 'Without label',
            MenuProps: {
              MenuListProps: {
                className: 'selectInputPropsStyle'
              }
            }
          }}>
          <MenuItem disabled value=''>
            <em>{_props.placeholder || t('placeholder')}</em>
          </MenuItem>
          {_props.customMenuOptions
            ? customMenuOptions.map((option: any) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.value}
                </MenuItem>
              ))
            : names.map((name: any) => (
                <MenuItem key={name} value={name} style={getStyles(theme)}>
                  {name}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </div>
  );
};
