import { AlertColor } from '@mui/material';

export const createErrorFetchPlugin = (showSnackBar: (text: string, typeColor: AlertColor) => void) => ({
  priority: 0,
  wrapStaticRequestPromise: (promise: any, _requestArgs: any) => {
    return promise.catch((error: any) => {
      const message = typeof error.error === 'string' ? error.error : error.error?.message ?? 'error in formio';
      showSnackBar(message, 'error');
      throw error;
    });
  }
});
