import config from 'config/config';
import { getToken, updateToken, isRefreshTokenExpired, logout } from 'context/Keycloak/Keycloak';

export const createAuthorizationFetchPlugin = () => ({
  priority: 0,
  preRequest: async (requestArgs: any) => {
    isRefreshTokenExpired() && logout();
    return updateToken(30).then(() => {
      if (/gateway/gi.test(requestArgs.url)) {
        requestArgs.url = config.formio.env_url + '/gateway' + requestArgs.url.split('/gateway').slice(1).join('');
      }

      requestArgs.opts.headers = {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      };
    });
  }
});
