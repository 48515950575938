import { useTheme } from '@mui/material/styles';

function IconSend(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3723"></path>
            <path
                fill="none"
                stroke={_props.color ? _props.color : theme.palette.primary.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M20 4.565L3.131 10.991l8.033 2.41 2.41 8.033z"
                data-name="Path 3735"
            ></path>
        </svg>
    );
}

export default IconSend;