import { tooltipClasses } from '@mui/material';

export const ArrowTooltipStyles = {
  [`& .${tooltipClasses.tooltip}`]: {
    bgcolor: (theme: any) => theme.palette.primary.main,
    color: (theme: any) => theme.palette.text.primary,
    fontSize: 11,
    borderRadius: (theme: any) => theme.shape.borderRadius
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: (theme: any) => theme.palette.primary.main
  }
};

export const WaringIconStyles = {
  borderRadius: (theme: any) => theme.shape.borderRadius,
  bgcolor: (theme: any) => theme.palette.secondary.main,
  padding: '3px'
};
