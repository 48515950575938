
import { useTheme } from '@mui/material/styles';
function IconChevronRight(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3699"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M9.31 6.71a1 1 0 000 1.41L13.19 12l-3.88 3.88a1 1 0 001.41 1.41l4.59-4.59a1 1 0 000-1.41L10.72 6.7a1 1 0 00-1.41.01z"
                data-name="Path 3700"
            ></path>
        </svg>
    );
}

export default IconChevronRight