import { Formio, Utils } from 'formiojs';
import { IFormioSubmissionRequest, IFormioSubmissionResponse } from '@ccs-dip/common/types/formio-types';
import { tryInitializeFieldSelectElement } from './tryInitializeFieldSelectElement';
import config from 'config/config';

//==============================================================
// extend global interfaces
//==============================================================

declare global {
  interface Window {
    formio_support: FormioRuntimeSupport;
  }
}

//==============================================================
// private variables
//==============================================================

const send_to_server = (data: IFormioSubmissionRequest): Promise<IFormioSubmissionResponse> => {
  const url = new URL('/api/formio-submission', config.server_url).href;

  return Formio.makeStaticRequest(url, 'POST', data).catch(() => {
    // swallow error, it will be displayed by the ErrorFetchPlugin
  });
};

//==============================================================
// class FormioRuntimeSupport
//==============================================================

export class FormioRuntimeSupport {
  send(form: any, submission: any, component: any) {
    if (!form.root.checkValidity(null, true, null, true)) {
      form.root.emit('error');
      return;
    }
    const tags = component.tags ?? [];
    const properties = component.properties ?? {};
    const data = {
      form: {
        name: form._form.name
      },
      component: {
        tags,
        properties
      },
      submission
    };

    send_to_server(data).then((response: IFormioSubmissionResponse) => {
      const messages = response?.messages ?? [];
      const submission = response.submission;
      messages.forEach((message) => {
        const component = Utils.getComponent(form.components, message.ref, false);
        component.setCustomValidity(
          {
            level: 'error',
            message: message.value
          },
          true,
          true
        );
        console.log('DEBUG set error', component);
      });
      if (submission !== undefined) {
        form.root.setSubmission(submission);
        form.root.emit('Success');
      }
    });
  }

  apiFieldCustomDefaultValue() {
    tryInitializeFieldSelectElement();
  }
}
