import { styled } from '@mui/material';

export const StyledLayout = styled('div')`
  display: fixed;
  height: 100%;
  width: 100%;
  padding: 12.5px 0px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
