import { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { contentText, getCurrencySymbol } from 'utils/Utils';
import Loader from 'component/organisms/Loader/Loader';
import BillingService from 'entities/Billing/BillingService';
import { Billing } from 'entities/Billing/IBilling';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import { BillingWrapper, BillingName } from './BillingBriefStyles';

interface IBillingBriefViewProps {
  billingNumber: string;
  billingActivityDesc: string;
}

export const BillingBriefView = (props: IBillingBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  const { t } = useTranslation<string>();
  const [billingDetails, setBillingDetails] = useState<Billing>();
  const service = new BillingService();
  const theme = useTheme();
  const { showSnackBar } = useSnackBar();

  const getBillingDetails = () => {
    return service
      .get(props.billingNumber + '?BillingActivityDesc=' + props.billingActivityDesc)
      .then((billing: any) => {
        setBillingDetails(billing.Billing);
        setisloading(false);
      })
      .catch((error: any) => {
        showSnackBar(error.message, 'error');
        setBillingDetails(undefined);
        setisloading(false);
      });
  };

  useEffect(() => {
    setisloading(true);
    props.billingNumber && getBillingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.billingNumber]);

  const billingInfo = {
    billingNumber: billingDetails?.BillingNumber,
    billingDesc: billingDetails?.BillingInfo?.BillingDesc,
    billingAccountNumber: billingDetails?.BillingInfo?.BillingAccountNumber,
    statusDesc: billingDetails?.BillingInfo?.BillingActivityInfo?.StatusDesc.replace('EN_', ''),
    bookingDt: billingDetails?.BillingInfo?.BookingDt,
    noteDt: billingDetails?.BillingInfo?.NoteDt,
    effectiveDt: billingDetails?.BillingInfo?.BillingPeriod?.EffectiveDt,
    expirationDt: billingDetails?.BillingInfo?.BillingPeriod?.ExpirationDt,
    totalDueAmt: billingDetails?.BillingInfo?.BalanceInfo?.TotalDueAmt?.Amt
  };

  return (
    <>
      {isloading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isloading}
          onClick={() => {}}>
          <Loader></Loader>
        </Backdrop>
      ) : (
        <Box id='BillingBriefView' sx={{ p: 3 }}>
          {billingDetails ? (
            <>
              <Stack direction={'row'} spacing={1} alignItems='center'>
                <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main, width: 70, height: 70 }}>
                  <Typography>BD</Typography>
                </Avatar>

                <Stack direction='column' spacing={1}>
                  {billingInfo?.billingNumber && (
                    <Box sx={BillingWrapper}>
                      <>
                        <Typography id='HeaderBillingNumber' sx={BillingName}>
                          {t('billingNumber')} {billingInfo.billingNumber}
                        </Typography>
                      </>
                    </Box>
                  )}
                </Stack>
              </Stack>

              <Stack
                spacing={1}
                sx={{ mt: 1, mb: 3, height: 'calc(100vh - 180px)', overflowY: 'auto', overflowX: 'hidden' }}>
                <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
                  {t('billingdetails')}
                </Typography>

                <Grid container spacing={2}>
                  {billingInfo?.billingNumber && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('billingNumber')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='BillingNumber' sx={contentText}>
                          {billingInfo?.billingNumber}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {billingInfo?.billingDesc && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('billingDesc')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='BillingDesc' sx={contentText}>
                          {billingInfo?.billingDesc}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {billingInfo?.billingAccountNumber && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('billingAccountNumber')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='BillingAccountNumber' sx={contentText}>
                          {billingInfo?.billingAccountNumber}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {billingInfo?.statusDesc && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('statusDesc')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='StatusDesc' sx={contentText}>
                          {billingInfo?.statusDesc}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {billingInfo?.bookingDt && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('bookingDt')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='BookingDt' sx={contentText}>
                          {billingInfo?.bookingDt}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {billingInfo?.noteDt && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('noteDt')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='NoteDt' sx={contentText}>
                          {billingInfo?.noteDt}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {billingInfo?.effectiveDt && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('effectiveDt')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='EffectiveDt' sx={contentText}>
                          {billingInfo?.effectiveDt}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {billingInfo?.expirationDt && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('expirationDt')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='ExpirationDt' sx={contentText}>
                          {billingInfo?.expirationDt}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {billingInfo?.totalDueAmt && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('totalDueAmt')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='TotalDueAmt' sx={contentText}>
                          {getCurrencySymbol(billingDetails?.BillingInfo.BalanceInfo.TotalDueAmt.CurCd.Value ?? '')}
                          {billingInfo?.totalDueAmt}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
              <Stack direction={'row'} spacing={1} justifyContent='space-between'>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    id='MoreDetails'
                    variant='subtitle2'
                    display='block'
                    sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
                    gutterBottom>
                    <Link to={`#`} style={{ color: theme.palette.secondary.main }}>
                      {t('moreDetails')}
                    </Link>
                  </Typography>
                </Stack>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    id='Edit'
                    variant='subtitle2'
                    display='block'
                    sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
                    gutterBottom>
                    <Link to={`#`} style={{ color: theme.palette.secondary.main }}>
                      {t('edit')}
                    </Link>
                  </Typography>
                </Stack>
              </Stack>
            </>
          ) : (
            <Typography>{t('noData')}</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default BillingBriefView;
