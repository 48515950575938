import { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import ArchiveService from 'entities/Archive/ArchiveService';
import { useTranslation } from 'react-i18next';
import { archiveDownload, contentText, hasValues } from 'utils/Utils';
import Tooltip from '@mui/material/Tooltip';
import Loader from 'component/organisms/Loader/Loader';
import { IArchive } from 'entities/Archive/IArchive';
import IconInvoice from 'component/atoms/Icons/IconInvoice';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import { atachFilename, attachFilenameWrapper, archiveNumberText, archiveText } from './ArchiveBriefViewStyles';

interface ArchiveBriefViewProps {
  archiveNumber: string;
}

const ArchiveBriefView = (props: ArchiveBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  const { t } = useTranslation<string>();
  const [archiveDetails, setArchiveDetails] = useState<IArchive>();
  const service = new ArchiveService();
  const { showSnackBar } = useSnackBar();

  const getArchiveDetails = () => {
    return service
      .get(props.archiveNumber ?? '')
      .then((archive) => {
        setArchiveDetails(archive);
        setisloading(false);
      })
      .catch((error: any) => {
        showSnackBar(error.message, 'error');
        setArchiveDetails(undefined);
        setisloading(false);
      });
  };

  useEffect(() => {
    setisloading(true);
    props.archiveNumber && getArchiveDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.archiveNumber]);

  const archiveInfo = {
    partyNumber: archiveDetails?.FileAttachmentReference?.PartyNumber,
    archiveNumber: archiveDetails?.ArchiveNumber,
    agencyId: archiveDetails?.FileAttachmentInfo?.ItemIdInfo?.AgencyId.Value,
    attachmentDesc: archiveDetails?.FileAttachmentInfo?.AttachmentDesc,
    mimeContentTypeCd: archiveDetails?.FileAttachmentInfo?.MIMEContentTypeCd.Value,
    attachmentFilename: archiveDetails?.FileAttachmentInfo?.AttachmentFilename,
    attachmentTypeRefCd: archiveDetails?.FileAttachmentInfo?.AttachmentTypeRefCd.Value,
    creationDt: archiveDetails?.FileAttachmentInfo?.CreationDt
  };

  return (
    <>
      {isloading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isloading}
          onClick={() => {}}>
          <Loader></Loader>
        </Backdrop>
      ) : (
        <Box id='ArchiveBriefView' sx={{ p: 3 }}>
          {hasValues(archiveInfo) ? (
            <>
              <Stack direction={'row'} spacing={1} alignItems='center'>
                <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main, width: 70, height: 70 }}>
                  <Typography>AD</Typography>
                </Avatar>

                <Stack direction='column' spacing={1}>
                  {archiveInfo?.attachmentFilename && (
                    <>
                      <Box sx={attachFilenameWrapper}>
                        <Tooltip title={archiveInfo.attachmentFilename}>
                          <Typography noWrap id='HeaderAttachmentFilename' sx={atachFilename}>
                            {t('archive')} {archiveInfo.attachmentFilename}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </>
                  )}

                  {archiveInfo?.archiveNumber && (
                    <>
                      <Typography id='HeaderArchiveNumber' sx={archiveNumberText}>
                        #{archiveInfo.archiveNumber}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>

              <Stack
                spacing={1}
                sx={{ mt: 1, mb: 3, height: 'calc(100vh - 220px)', overflowY: 'auto', overflowX: 'hidden' }}>
                <Typography noWrap sx={archiveText}>
                  {t('archivedetails')}
                </Typography>

                <Grid container spacing={2}>
                  {archiveInfo?.partyNumber && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('partyNumber')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='PartyNumber' sx={contentText}>
                          {archiveInfo?.partyNumber}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {archiveInfo?.archiveNumber && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('archiveNumber')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='ArchiveNumber' sx={contentText}>
                          {archiveInfo?.archiveNumber}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {archiveInfo?.agencyId && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('agencyId')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='AgencyId' sx={contentText}>
                          {archiveInfo?.agencyId}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {archiveInfo?.attachmentDesc && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('attachmentDesc')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='AttachmentDesc' sx={contentText}>
                          {archiveInfo?.attachmentDesc}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {archiveInfo?.mimeContentTypeCd && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('mimeContentTypeCd')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='MIMEContentTypeCd' sx={contentText}>
                          {archiveInfo?.mimeContentTypeCd}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {archiveInfo?.attachmentFilename && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('attachmentFilename')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='AttachmentFilename' sx={contentText}>
                          {archiveInfo?.attachmentFilename}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {archiveInfo?.attachmentTypeRefCd && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('attachmentTypeRefCd')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='AttachmentTypeRefCd' sx={contentText}>
                          {archiveInfo?.attachmentTypeRefCd}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {archiveInfo?.creationDt && (
                    <>
                      <Grid item xs={6}>
                        <Typography sx={contentText}>{t('CreationDt')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography id='CreationDt' sx={contentText}>
                          {archiveInfo?.creationDt}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>

              <Stack direction={'row'} spacing={1} justifyContent='space-between'>
                <Stack direction={'column'} spacing={1}>
                  <Box sx={{ pt: 2 }}>
                    {t('Download')} {archiveInfo?.mimeContentTypeCd}
                    <IconButton
                      style={{ paddingRight: '10px', display: 'inline-block' }}
                      aria-label='Download'
                      onClick={() => archiveDownload(archiveInfo?.archiveNumber ?? '', showSnackBar)}>
                      <IconInvoice> </IconInvoice>
                    </IconButton>
                  </Box>
                </Stack>
              </Stack>
            </>
          ) : (
            <Typography>{t('noData')}</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default ArchiveBriefView;
