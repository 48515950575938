
import { useTheme } from '@mui/material/styles';
function IconTimer(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <g data-name="Group 7038">
                <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2434"></path>
            </g>
            <g data-name="Group 7041">
                <g data-name="Group 7040">
                    <g data-name="Group 7039">
                        <path
                            fill={_props.color ? _props.color : theme.palette.primary.main}
                            d="M15 1H9v2h6zm-4 13h2V8h-2zm8.03-6.61l1.42-1.42a11.048 11.048 0 00-1.41-1.41l-1.42 1.42a9 9 0 101.41 1.41zM12 20a7 7 0 117-7 6.995 6.995 0 01-7 7z"
                            data-name="Path 3727"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default IconTimer;