import { styled, Theme } from '@mui/material';

export const displayForm = { textTransform: 'capitalize', display: 'inline', fontSize: '14px' };
export const iconWrapper = { color: (theme: Theme) => theme.palette.secondary.main };
export const titleText = {
  whiteSpace: 'nowrap',
  width: '175px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  textTransform: 'capitalize'
};
export const toolBar = { display: 'flex', justifyContent: 'flex-end', gap: '20px', py: 2 };

export const toolbarWrap = { width: '400px', display: 'inline-flex', justifyContent: 'flex-end' };
export const uploadButton = {
  bgcolor: (theme: Theme) => theme.palette.secondary.main,
  mr: 2,
  fontSize: '12px',
  cursor: 'pointer'
};

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  bgcolor: (theme: any) => theme.palette.secondary.main
});
