import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Box,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PolicyService from 'entities/Policy/PolicyService';
import { useTheme } from '@mui/material/styles';
import { IPolicy } from 'entities/Policy/IPolicy';
import { contentText, getCurrencySymbol } from 'utils/Utils';
import Loader from 'component/organisms/Loader/Loader';
import Tooltip from '@mui/material/Tooltip';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import { coverageDetails } from '../Policy/PolicyBriefViewStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  avatar,
  contentWrap,
  insureDetailsText,
  leadInsurerName,
  leadInsurerWrapper,
  paymentDetailsText,
  policyNumber
} from './OfferBriefViewStyles';

interface IOfferBriefViewProps {
  policyNumber: string;
}

export const OfferBriefView = (props: IOfferBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const [offerDetails, setOfferDetails] = useState<IPolicy>();
  const service = new PolicyService();
  const { showSnackBar } = useSnackBar();

  const getOfferDetails = () => {
    return service
      .get(props.policyNumber + '?PolicyVersion=Q' ?? '')
      .then((policy: any) => {
        setOfferDetails(policy.policyMessage.Policy[0]);
        setisloading(false);
      })
      .catch((error: any) => {
        showSnackBar(error.message, 'error');
        setOfferDetails(undefined);
        setisloading(false);
      });
  };

  useEffect(() => {
    setisloading(true);
    props.policyNumber && getOfferDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.policyNumber]);

  const offerInfo = {
    policyNumber: offerDetails?.PolicyNumber,
    companyPolicyNumber: offerDetails?.CompanysPolicyNumber,
    lobDesc: offerDetails?.LOBDesc,
    leadInsurerName: offerDetails?.LeadInsurerName,
    //contactPerson: offerDetails?.ItemIdInfo?.AgencyId?.Value,
    originalPolicyInceptionDt: offerDetails?.OriginalPolicyInceptionDt,
    expirationDt: offerDetails?.ContractTerm?.ExpirationDt,
    contractTerm: offerDetails?.ContractTerm?.DurationPeriod?.NumUnits,
    paymentMethod: offerDetails?.PaymentOption?.[0]?.MethodPaymentRefCd?._Desc,
    // Update to handle an array of coverages
    coverages: offerDetails?.DwellFireLineBusiness?.Coverage || [],
    // coverageDesc: offerDetails?.DwellFireLineBusiness?.Coverage?.[0]?.CoverageDesc,
    // netAmt: offerDetails?.DwellFireLineBusiness?.Coverage?.[0]?.NetAmt?.Amt,
    billingPeriod: offerDetails?.PaymentOption?.[0]?.BillingPeriod?.DurationPeriod?.NumUnits,
    companysQuoteNumber: offerDetails?.QuoteInfo.CompanysQuoteNumber,
    internalQuoteNumber: offerDetails?.QuoteInfo.QuoteNumber,
    grossPremiumAmt: offerDetails?.GrossPremiumAmt?.Amt,
    taxAmt: offerDetails?.TaxAmt?.Amt,
    renewalPremiumAmt: offerDetails?.RenewalPremiumAmt?.Amt,
    ibanNumber: offerDetails?.Account?.AccountNumberId?.Value
  };

  return (
    <>
      {isloading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isloading}
          onClick={() => {}}>
          <Loader></Loader>
        </Backdrop>
      ) : (
        <Box id='OfferBriefView' sx={{ p: 3 }}>
          {offerDetails ? (
            <>
              <Stack direction={'row'} spacing={1} alignItems='center'>
                <Avatar sx={avatar}>
                  <Typography>OD</Typography>
                </Avatar>

                <Stack direction='column' spacing={1}>
                  {offerInfo?.leadInsurerName && (
                    <>
                      <Box sx={leadInsurerWrapper}>
                        <Tooltip title={offerInfo.leadInsurerName}>
                          <Typography id='HeaderLeadInsurerName' sx={leadInsurerName}>
                            {offerInfo.leadInsurerName}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </>
                  )}

                  {offerInfo?.companysQuoteNumber && (
                    <>
                      <Typography id='HeaderPolicyNumber' sx={policyNumber}>
                        #{offerInfo?.companysQuoteNumber}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>

              <Stack spacing={1} sx={contentWrap}>
                {(offerInfo.companysQuoteNumber ||
                  offerInfo.lobDesc ||
                  offerInfo.leadInsurerName ||
                  //offerInfo.contactPerson ||
                  offerInfo.originalPolicyInceptionDt ||
                  offerInfo.expirationDt ||
                  offerInfo.contractTerm) && (
                  <>
                    <Typography sx={insureDetailsText}>{t('insuranceDetails')}</Typography>

                    <Grid container spacing={2}>
                      {offerInfo?.companysQuoteNumber && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('offerNumber')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='CompanysQuoteNumber' sx={contentText}>
                              {offerInfo?.companysQuoteNumber}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.internalQuoteNumber && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('internalOfferNumber')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='InternalOfferNumber' sx={contentText}>
                              {offerInfo?.internalQuoteNumber}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.lobDesc && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('lOBDesc')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='LOBDesc' sx={contentText}>
                              {offerInfo?.lobDesc}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.leadInsurerName && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('leadInsurerName')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='LeadInsurerName' sx={contentText}>
                              {offerInfo?.leadInsurerName}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {/* {offerInfo?.contactPerson && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('contactPerson')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='ContactPerson' sx={contentText}>
                              {offerInfo?.contactPerson}
                            </Typography>
                          </Grid>
                        </>
                      )} */}

                      {offerInfo?.originalPolicyInceptionDt && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('originalPolicyInceptionDt')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='OriginalPolicyInceptionDt' sx={contentText}>
                              {offerInfo?.originalPolicyInceptionDt}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.expirationDt && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('expirationDt')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='ExpirationDt' sx={contentText}>
                              {offerInfo?.expirationDt}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.contractTerm && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('contractTerm')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='ContractTerm' sx={contentText}>
                              {offerInfo?.contractTerm}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

                {offerInfo?.lobDesc !== 'PKG' && offerInfo.coverages.length > 0 && (
                  <>
                    <Divider />
                    <Typography sx={coverageDetails}>{t('coverageDetails')}</Typography>
                    {offerInfo.coverages.map((coverage, index) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1-content'
                          id='panel1-header'>
                          <Typography variant='subtitle2'>{coverage.CoverageDesc}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography sx={contentText}>{t('coverageDesc')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography id={`CoverageDesc-${index}`} sx={contentText}>
                                {coverage.CoverageDesc}
                              </Typography>
                            </Grid>

                            <Grid item xs={6}>
                              <Typography sx={contentText}>{t('netAmt')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography id={`NetAmt-${index}`} sx={contentText}>
                                {getCurrencySymbol(coverage.NetAmt?.CurCd?.Value ?? '')}
                                {coverage.NetAmt?.Amt}
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                )}

                {(offerInfo.billingPeriod ||
                  offerInfo.grossPremiumAmt ||
                  offerInfo.taxAmt ||
                  offerInfo.renewalPremiumAmt ||
                  offerInfo.ibanNumber ||
                  offerInfo?.paymentMethod) && (
                  <>
                    <Divider />
                    <Typography sx={paymentDetailsText}>{t('paymentDetails')}</Typography>

                    <Grid container spacing={2}>
                      {offerInfo?.billingPeriod && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('billingPeriod')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='BillingPeriod' sx={contentText}>
                              {offerInfo?.billingPeriod}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.grossPremiumAmt && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('grossPremiumAmt')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='GrossPremiumAmt' sx={contentText}>
                              {getCurrencySymbol(offerDetails?.GrossPremiumAmt?.CurCd?.Value ?? '')}
                              {offerInfo?.grossPremiumAmt}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.taxAmt && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('taxAmt')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='TaxAmt' sx={contentText}>
                              {getCurrencySymbol(offerDetails?.TaxAmt?.CurCd?.Value ?? '')} {offerInfo?.taxAmt}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.renewalPremiumAmt && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('renewalPremiumAmt')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='RenewalPremiumAmt' sx={contentText}>
                              {getCurrencySymbol(offerDetails?.RenewalPremiumAmt?.CurCd?.Value ?? '')}
                              {offerInfo?.renewalPremiumAmt}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo.ibanNumber && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('ibanNumber')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='AccountNumberId' sx={contentText}>
                              {offerInfo?.ibanNumber}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {offerInfo?.paymentMethod && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('paymentMethod')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='PaymentMethod' sx={contentText}>
                              {offerInfo?.paymentMethod}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Stack>

              <Stack direction={'row'} spacing={1} justifyContent='space-between'>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    id='MoreDetails'
                    variant='subtitle2'
                    display='block'
                    sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
                    gutterBottom>
                    <Link to={`#`} style={{ color: theme.palette.secondary.main }}>
                      {t('moreDetails')}
                    </Link>
                  </Typography>
                </Stack>
                <Stack direction={'column'} spacing={1}>
                  <Typography
                    id='Edit'
                    variant='subtitle2'
                    display='block'
                    sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
                    gutterBottom>
                    <Link to={`#`} style={{ color: theme.palette.secondary.main }}>
                      {t('edit')}
                    </Link>
                  </Typography>
                </Stack>
              </Stack>
            </>
          ) : (
            <Typography>{t('noData')}</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default OfferBriefView;
