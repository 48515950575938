
function IconSolidClock(_props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3701"></path>
            <path
                fill={_props.color ? _props.color : "#00005a"}
                d="M11.99 2A10 10 0 1022 12 10 10 0 0011.99 2zM12 20a8 8 0 118-8 8 8 0 01-8 8zm-.22-13h-.06a.717.717 0 00-.72.72v4.72a.99.99 0 00.49.86l4.15 2.49a.715.715 0 10.73-1.23l-3.87-2.3V7.72a.717.717 0 00-.72-.72z"
                data-name="Path 3702"
            ></path>
        </svg>
    );
}

export default IconSolidClock;