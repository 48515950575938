import { Dispatch, SetStateAction } from 'react';
import { Box, Tooltip, Chip, Typography } from '@mui/material';
import FormioReportIcons from './FormioReportIcons';
import { IMessage, MessageType } from '@ccs-dip/common/types/message';
import FormioReportStyles from './FormioReportStyles';
import { useTranslation } from 'react-i18next';

interface FormReportHeaderProps {
  messages: IMessage[];
  maximize: boolean;
  setMaximize: Dispatch<SetStateAction<boolean>>;
}

const FormReportHeader = ({ messages, maximize, setMaximize }: FormReportHeaderProps) => {
  const { t } = useTranslation<string>();

  const getMessageDetails = () => {
    return {
      Total: messages.length,
      Info: messages.filter((a: IMessage) => a.type === MessageType.Info).length,
      Error: messages.filter((a: IMessage) => a.type === MessageType.Error).length,
      Warning: messages.filter((a: IMessage) => a.type === MessageType.Warning).length,
      Verbose: messages.filter((a: IMessage) => a.type === MessageType.Verbose).length
    };
  };

  const messageDetails: Record<string, number> = getMessageDetails();

  const getNonZeroCount = () => {
    return Object.keys(messageDetails).filter((a) => a !== 'Total' && messageDetails[a]);
  };

  const filteredCount: string[] = getNonZeroCount();

  const FormReportTitle = () => {
    return (
      <Box>
        <Box sx={FormioReportStyles.headerTitle}>
          <FormioReportIcons.AssessmentOutlinedIcon sx={FormioReportStyles.outlineIcon} fontSize='large' />
          {t('formReport')}
          <Tooltip title='Total'>
            <Chip label={messageDetails['Total']} sx={FormioReportStyles.chipWrapper} color='primary' />
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const MessageTypeChips = () => {
    return (
      <>
        {filteredCount.map((itm) => {
          const value = MessageType[itm as keyof typeof MessageType];
          return (
            <Box sx={FormioReportStyles.menuWrapper} key={itm}>
              <Tooltip title={t('typeofMessage')}>
                <Chip label={messageDetails[itm]} sx={FormioReportStyles.toolsBarWrapper(value)} color='primary' />
              </Tooltip>
              <Typography sx={FormioReportStyles.toolText}>{itm}</Typography>
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <Box sx={FormioReportStyles.reportOutliner}>
      <FormReportTitle />
      <Box sx={FormioReportStyles.filterWrapper}>
        <MessageTypeChips />
        <Box>
          {maximize ? (
            <Tooltip title={t('minimize')}>
              <FormioReportIcons.CloseFullscreenOutlinedIcon
                sx={FormioReportStyles.cursorPointer}
                onClick={() => {
                  setMaximize(false);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t('maximize')}>
              <FormioReportIcons.OpenInFullOutlinedIcon
                sx={FormioReportStyles.cursorPointer}
                onClick={() => {
                  setMaximize(true);
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FormReportHeader;
