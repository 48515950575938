export default class BaseConfig {
    isValid = false;
    validation_messages = [];
    require(name, value) {
        if (!value) {
            this.validation_messages.push(`required setting '${name}' has no value`);
        }
    }
    validate() {
        this.validation_messages = [];
        this.validate_fields();
        this.isValid = this.validation_messages.length === 0;
    }
}
