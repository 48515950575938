import { useTheme } from '@mui/material/styles';
function IconCloudy(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3713"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M19.35 10.04a7.492 7.492 0 00-14-2A6 6 0 006 20h13a4.986 4.986 0 00.35-9.96zM19 18H6a4 4 0 010-8h.71a5.5 5.5 0 0110.79 1.5v.5H19a3 3 0 010 6z"
                data-name="Path 3714"
            ></path>
        </svg>
    );
}

export default IconCloudy;