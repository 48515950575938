import { useTheme } from '@mui/material/styles';
function IconCCSLogo(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "69"}
            height={_props.height ? _props.height : "35"}
            viewBox="0 0 69.5 34.786"
        >
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                fillRule="evenodd"
                d="M7.972 21.751a1.215 1.215 0 00-2.2 1.027 12.737 12.737 0 100-10.779 1.215 1.215 0 102.2 1.026 10.31 10.31 0 110 8.726"
            ></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                fillRule="evenodd"
                d="M17.663 22.863l-.225-.011a5.262 5.262 0 01-3.947-1.9 1.077 1.077 0 00-1.39-.226 1.093 1.093 0 00-.514.76 1.066 1.066 0 00.238.864 7.724 7.724 0 005.307 2.641l.293.022q.2.01.395.01a5.753 5.753 0 003.945-1.4 4.919 4.919 0 001.649-3.384c.123-2.428-1.411-4.317-4.1-5.053l-1.309-.354a1.126 1.126 0 00-.774.054 1.077 1.077 0 00-.589.72 1.119 1.119 0 00.805 1.3l1.3.352c1.2.328 2.607 1.092 2.517 2.868-.093 1.849-1.886 2.776-3.6 2.739"
            ></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                fillRule="evenodd"
                d="M17.591 9.744l-.293-.022a5.851 5.851 0 00-4.34 1.4 4.918 4.918 0 00-1.649 3.384c-.123 2.428 1.41 4.317 4.1 5.053l1.345.364a1.057 1.057 0 00.228.036h.054a1.077 1.077 0 00.856-1.729 1.132 1.132 0 00-.612-.4l-1.305-.353c-1.2-.328-2.607-1.092-2.517-2.868.094-1.862 1.9-2.79 3.622-2.739l.2.011a5.263 5.263 0 013.948 1.9 1.078 1.078 0 001.39.225 1.093 1.093 0 00.514-.76 1.069 1.069 0 00-.238-.864 7.724 7.724 0 00-5.303-2.638"
            ></path>
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                fillRule="evenodd"
                d="M27.98 6.806a14.93 14.93 0 012.986 4.258 1.215 1.215 0 002.2-1.027 17.386 17.386 0 100 14.712 1.215 1.215 0 00-2.2-1.026A14.975 14.975 0 1127.98 6.806M59.663 21.939l1.144-2.438a9.12 9.12 0 003.909 1.027c1.061 0 1.7-.32 1.7-1.061-.017-.691-.64-.977-2.072-1.247-2.645-.522-4.465-1.331-4.465-3.74 0-2.292 1.533-3.757 4.633-3.757a10.246 10.246 0 014.549.927l-1.2 2.392a9.271 9.271 0 00-3.319-.724c-1.028 0-1.617.32-1.617.927 0 .758.927.994 2.662 1.348 2.308.472 3.909 1.264 3.909 3.656 0 2.476-1.466 3.841-4.65 3.841a11.168 11.168 0 01-5.187-1.15M55.087 20.342a3.128 3.128 0 01-3.3-3.471 3.07 3.07 0 013.3-3.386 3.279 3.279 0 012.418.941l1.305-2.661a6.987 6.987 0 00-3.829-1.04c-3.926 0-6.267 2.325-6.267 6.149 0 3.841 2.342 6.217 6.267 6.217a6.84 6.84 0 003.829-1.009l-1.31-2.663a3.269 3.269 0 01-2.409.923M44.72 20.342a3.128 3.128 0 01-3.3-3.471 3.07 3.07 0 013.3-3.386 3.279 3.279 0 012.418.941l1.31-2.663a6.987 6.987 0 00-3.829-1.04c-3.925 0-6.267 2.325-6.267 6.149 0 3.841 2.342 6.217 6.267 6.217a6.839 6.839 0 003.819-1.007l-1.31-2.663a3.268 3.268 0 01-2.408.923"
            ></path>
        </svg>
    );
}

export default IconCCSLogo;
