import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import EntityListView from '../EntityListView/EntityListView';
import { IPolicy } from 'entities/Policy/IPolicy';
import { Box } from '@mui/material';
import IconInsuranceCar2 from 'component/atoms/Icons/IconInsuranceCar2';
import { useTranslation } from 'react-i18next';
import i18n from 'config/lang/i18n';

//===============================================
// private variables
//===============================================

//===============================================
// Component props interface
//===============================================

interface IOfferListViewProps {
  rows: IPolicy[];
}

//===============================================
// Component render function
//===============================================nameofInsurer

const OfferListView = (props: IOfferListViewProps) => {
  const { t } = useTranslation<string>();
  const { rows } = props;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        valueGetter: (params: any) => params.row.id
      },
      {
        field: 'PolicyType',
        headerName: t(''),
        type: 'string',
        flex: 0,
        renderCell: () => (
          <>
            <Box sx={{ pr: 2 }}>
              <IconInsuranceCar2></IconInsuranceCar2>
            </Box>
          </>
        )
      },
      {
        field: 'LeadInsurerName',
        headerName: t('nameofInsurer'),
        type: 'string',
        flex: 1,
        valueGetter: (params: any) => params.row.LeadInsurerName
      },
      {
        field: 'PolicyNumber',
        headerName: t('internalOfferNumber'),
        type: 'string',
        flex: 1,
        valueGetter: (params: any) => params.row.PolicyNumber
      },
      {
        field: 'CompanysQuoteNumber',
        headerName: t('offerNumber'),
        type: 'string',
        flex: 1,
        valueGetter: (params: any) => params.row.QuoteInfo.CompanysQuoteNumber
      },
      { field: 'Characteristic', headerName: t('referenceCharacter'), type: 'string', flex: 1 },
      { field: 'OriginalPolicyInceptionDt', headerName: t('originalPolicyInceptionDt'), type: 'string', flex: 1 }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return (
    <EntityListView
      id='OfferListView'
      columns={columns}
      rows={rows}
      getId={(row) => row.PolicyNumber}
      briefViewType='OfferBriefView'
    />
  );
};

export default OfferListView;
