import { ToggleButton, styled } from '@mui/material';
//import InputBase from '@mui/material/InputBase';
export const ToggleButtonSwitch = styled(ToggleButton)(({ theme }) => ({
  mr: 2,
  color: theme.palette.primary.main,
  fontSize: 14,
  width: 66,
  height: 34,
  margin: 1,
  border: 0,
  '&.MuiToggleButtonGroup-grouped': {
    borderRadius: '10px !important',
    fontSize: '14px !important'
  },
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(0, 70, 255, 0.05)'
  },
  '&:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(0, 70, 255, 0.05)'
  }
}));
export const ToggleButtonStyles = { typography: 'body2', mb: 2 };
