import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { BoxSection, TabsWrapper, TabcontentStyle, BoxChildren } from './TabPanelStyles';

export default function MuiTabPanel(_props: any) {
  const [value, setValue] = useState(_props.taboption as number);
  const navigate = useNavigate();
  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
    navigate(`/party/partydetails/${_props.partyNumber}/${newValue}`);
  };
  useEffect(() => {
    setValue(_props.taboption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_props.taboption]);

  return (
    <Box sx={BoxSection}>
      <Tabs
        id='SubMenuTabs'
        value={value}
        sx={TabsWrapper}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'>
        {_props?.itemOptions?.map((itm: any, index: number) => (
          <Tab sx={TabcontentStyle} label={itm} key={index} />
        ))}
      </Tabs>
      <Box sx={BoxChildren}>{_props.children}</Box>
    </Box>
  );
}
