import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { IStatus } from './IFormioReport';

const reportOutliner = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  zIndex: 1000
};

const iconStyle = { mr: 1 };

const outlineIcon = { mr: 1 };

const headerTitle = {
  fontSize: '14px',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'center',
  alignItems: 'center',
  my: 1.5
};

const chipWrapper = {
  background: 'grey.500',
  fontSize: '12px',
  p: 0.5,
  m: 0.5
};

const filterFormControl = { m: 1, minWidth: 120 };

const filterWrapper = {
  fontSize: '13px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
  fontWeight: 'bold'
};

const headerWrapper = (status: number) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    borderColor: 'grey.500',
    backgroundColor: FormioReportStyles.setBackgroundColor(status).color
  };
};

const headerColumn = { flexGrow: 1, width: '30%', display: 'flex', fontSize: 14, fontWeight: 'bold', py: 1, px: 1.5 };

const headerContent = { flexGrow: 1, width: '30%', display: 'flex', fontSize: 14, py: 1, px: 1.5 };

const reportWrapper = { border: 1, borderColor: 'grey.500', my: 1 };

const setBackgroundColor = (caseType: number) => {
  const status: IStatus = { color: 'rgb(255, 255, 255)', icon: <></>, text: 'Verbose' };
  switch (caseType) {
    case 0:
      status.color = '#FFCBCB'; // Red
      status.icon = <ErrorOutlineOutlinedIcon sx={iconStyle} fontSize='small' />;
      status.text = 'Error';
      break;
    case 1:
      status.color = '#FFBB70'; // Yellow
      status.icon = <WarningAmberOutlinedIcon sx={iconStyle} fontSize='small' />;
      status.text = 'Warning';
      break;
    case 2:
      status.color = '#C6EBC5'; // Green
      status.icon = <CheckCircleOutlineOutlinedIcon sx={iconStyle} fontSize='small' />;
      status.text = 'Info';
      break;
    case 3:
      status.color = '#5BBCFF'; // Blue
      status.icon = <TextSnippetOutlinedIcon fontSize='small' />;
      status.text = 'Verbose';
      break;
    default:
      status.color = 'rgb(255, 255, 255)'; // White (default);
      status.icon = <></>;
      status.text = 'Verbose';
  }
  return status;
};

const menuWrapper = {
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mx: 1.5
};

const toolsBarWrapper = (value: number) => ({
  background: setBackgroundColor(value).color,
  fontSize: '12px',
  p: 0.5,
  m: 0.5,
  color: '#000'
});

const cursorPointer = { cursor: 'pointer' };

const toolText = { textTransform: 'capitalize', fontSize: '14px', fontWeight: 'bold', display: 'flex' };

const FormioReportStyles = {
  setBackgroundColor,
  filterWrapper,
  filterFormControl,
  chipWrapper,
  headerTitle,
  outlineIcon,
  reportOutliner,
  menuWrapper,
  toolsBarWrapper,
  toolText,
  cursorPointer,
  iconStyle,
  headerWrapper,
  headerContent,
  reportWrapper,
  headerColumn
};

export default FormioReportStyles;
