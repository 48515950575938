import { TextField, styled } from '@mui/material';

export const InputTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid',
    borderColor: '#E0E3E7',
    fontSize: 16,
    height: 19,
    background: '#fff',
    padding: '10px 12px',
    '&:focus': {
      borderColor: theme.palette.secondary.main
    }
  },
  '& label.Mui-focused': {
    color: '#fff'
  },
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  '&:focus': {
    borderColor: theme.palette.secondary.main
  }
}));
