import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DesignSystem } from '../../../temp/DesignSystem/DesignSystem';
import { useTranslation } from 'react-i18next';
import UserAssociate from 'temp/UserAssociate/UserAssociate';
import TranslationDataGrid from '../Translation/TranslationDataGrid';
import FormBuilderPage from '../Formbuilder/FormBuilderPage';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ContrastIcon from '@mui/icons-material/Contrast';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TonalityIcon from '@mui/icons-material/Tonality';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SwaggerBuilderPage from '../SwaggerBuilder/SwaggerBuilderPage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ISettingProps {
  children?: React.ReactNode;
  handleTheme?: (event: any) => void;
  theme?: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CustomPanelBuilder(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ px: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function SettingsPage(props: ISettingProps) {
  const { t } = useTranslation<string>();

  const [value, setValue] = React.useState<number>(0);
  const { key = 0 } = useParams();
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    setValue(key ? Number(key) : 0);
  }, [key]);
  return (
    <Box id='SettingsPage' sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab
            icon={<SubtitlesIcon sx={{ fontSize: '20px', color: (theme) => theme.palette.secondary.main }} />}
            iconPosition='start'
            sx={{ fontSize: '14px' }}
            label={t('translation')}
            {...a11yProps(0)}
          />
          <Tab
            iconPosition='start'
            icon={<ContrastIcon sx={{ fontSize: '18px', color: (theme) => theme.palette.secondary.main }} />}
            sx={{ fontSize: '14px' }}
            label={t('theme')}
            {...a11yProps(1)}
          />
          <Tab
            iconPosition='start'
            icon={<AccountBoxIcon sx={{ fontSize: '18px', color: (theme) => theme.palette.secondary.main }} />}
            sx={{ fontSize: '14px' }}
            label={t('associateUser')}
            {...a11yProps(2)}
          />
          <Tab
            iconPosition='start'
            icon={<TonalityIcon sx={{ fontSize: '18px', color: (theme) => theme.palette.secondary.main }} />}
            sx={{ fontSize: '14px' }}
            label={t('formbuilder')}
            {...a11yProps(3)}
          />
          <Tab
            iconPosition='start'
            icon={<ApartmentIcon sx={{ fontSize: '18px', color: (theme) => theme.palette.secondary.main }} />}
            sx={{ fontSize: '14px' }}
            label={t('swaggerbuilder')}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <TranslationDataGrid />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DesignSystem theme={props.theme} handleTheme={props.handleTheme}></DesignSystem>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <UserAssociate />
      </CustomTabPanel>
      <CustomPanelBuilder value={value} index={3}>
        <FormBuilderPage></FormBuilderPage>
      </CustomPanelBuilder>
      <CustomPanelBuilder value={value} index={4}>
        <SwaggerBuilderPage></SwaggerBuilderPage>
      </CustomPanelBuilder>
    </Box>
  );
}
