import { useTheme } from '@mui/material/styles';

function IconEdit(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" data-name="Path 3717"></path>
            <path
                fill={_props.color ? _props.color : theme.palette.secondary.main}
                d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3a1 1 0 00-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a1 1 0 000-1.41l-2.34-2.34a.982.982 0 00-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
                data-name="Path 3718"
            ></path>
        </svg>
    );
}

export default IconEdit;