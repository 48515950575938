import { useState } from 'react';
import { Box } from '@mui/material';

import FormReportHeader from './FormioReportHeader';
import { FormioReportOverviewProps } from './IFormioReport';
import FormOverviewGrid from './FormOverviewGrid';

const FormioReportOverview = ({ reportMessage, formVersion, isPreviewMode }: FormioReportOverviewProps) => {
  const [maximize, setMaximize] = useState(true);

  if (!reportMessage.length || isPreviewMode) return null;

  return (
    <Box key={formVersion}>
      <FormReportHeader
        maximize={maximize}
        messages={reportMessage}
        setMaximize={setMaximize}
      />
      <FormOverviewGrid key={formVersion} formVersion={formVersion} maximize={maximize} messages={reportMessage}></FormOverviewGrid>
    </Box>
  );
};

export default FormioReportOverview;
