import { useState } from 'react';

import Smartsearch from 'component/organisms/Smartsearch/Smartsearch';
import { Box } from '@mui/material';

export const Action = () => {
  const [searchVal, setSearchVal] = useState('');
  const [count, setCount] = useState(0);
  const handleInputChange = (val: any) => {
    setSearchVal(val);
  };
  const clearInput = () => {
    setSearchVal('');
    setCount(count + 1);
  };

  return (
    <>
      <Box id='ActionBox' sx={{ width: '100%', display: 'flex', p: 2.5, flexGrow: 1 }}>
        <Smartsearch
          key={count}
          searchVal={searchVal}
          clearInput={clearInput}
          handleInputChange={handleInputChange}></Smartsearch>
      </Box>
    </>
  );
};
