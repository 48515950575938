import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from '@mui/material';
import { IComponent } from '@ccs-dip/common/types/formio-types';
import { Form } from '@formio/react';
import { OpenApiService } from 'entities/OpenApi/OpenApiService';
import { FieldInfo } from '@ccs-dip/common/FieldInfo';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import { fieldSelectComponent } from 'utils/formio/replaceEditFormFunctions';

export interface IFieldDialogProps {
  open: boolean;
  selectedField: IComponent | null;
  onClose: () => void;
  onSelectField: (fieldInfo: FieldInfo) => void;
}

const FieldDialog: React.FC<IFieldDialogProps> = ({ open, onClose, onSelectField }) => {
  const openApiService = new OpenApiService();
  const { showSnackBar } = useSnackBar();

  const handleFieldSelection = async () => {
    try {
      const fieldSelector = document.getElementById('fieldSelector') as HTMLInputElement | null;

      if (fieldSelector) {
        const value = fieldSelector.value;
        const fieldInfo = await openApiService.search(value ?? '');

        if (fieldInfo) {
          if (fieldInfo.type === 'object' || fieldInfo.type === 'array') {
            showSnackBar('Field type object or array is not selectable', 'error');
          } else {
            onSelectField(fieldInfo);
          }
        } else {
          showSnackBar('Field info not found', 'error');
        }
      }
    } catch (error: any) {
      showSnackBar(error.message, 'error');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>API Key Selection Dialog</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <Form
            form={{
              type: 'form',
              components: [
                fieldSelectComponent,
                {
                  type: 'hidden',
                  key: 'key',
                  label: 'Property key'
                }
              ]
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleFieldSelection} color='primary'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FieldDialog;
