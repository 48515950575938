export const backDrop = { color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 };
export const avatar = { bgcolor: (theme: any) => theme.palette.secondary.main, width: 70, height: 70 };
export const scrollableContent = {
  mt: 1,
  mb: 3,
  height: 'calc(100vh - 180px)',
  overflowY: 'auto',
  overflowX: 'hidden'
};
export const insuranceDetails = {
  mt: 1,
  mb: 1,
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};
export const coverageDetails = {
  mt: 1,
  mb: 1,
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};
export const paymentDetails = {
  mt: 1,
  mb: 1,
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};
export const moreDetails = { color: (theme: any) => theme.palette.primary.main, ml: 2 };
export const editDetails = { color: (theme: any) => theme.palette.primary.main, ml: 2 };
export const wrapper = { width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
export const wrapperText = {
  fontSize: '24px',
  color: (theme: any) => theme.palette.primary.main,
  fontWeight: 'bold',
  width: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
