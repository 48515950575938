
import { useTheme } from '@mui/material/styles';
function IconHide(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "24"}
            height={_props.height ? _props.height : "24"}
            viewBox="0 0 24 24"
        >
            <path
                fill="none"
                d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
                data-name="Path 3652"
            ></path>
            <path
                fill={_props.color ? _props.color : theme.palette.secondary.main}
                d="M12 6a9.77 9.77 0 018.82 5.5 9.647 9.647 0 01-2.41 3.12l1.41 1.41A11.8 11.8 0 0023 11.5 11.834 11.834 0 008.36 4.57l1.65 1.65A10.108 10.108 0 0112 6zm-1.07 1.14L13 9.21a2.5 2.5 0 011.28 1.28l2.07 2.07a4.679 4.679 0 00.14-1.07A4.483 4.483 0 0012 7a4.244 4.244 0 00-1.07.14zM2.01 3.87l2.68 2.68A11.738 11.738 0 001 11.5 11.827 11.827 0 0012 19a11.73 11.73 0 004.32-.82l3.42 3.42 1.41-1.41L3.42 2.45zm7.5 7.5l2.61 2.61A.5.5 0 0112 14a2.5 2.5 0 01-2.5-2.5c0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75a4.6 4.6 0 00-.36 1.78 4.505 4.505 0 006.27 4.14l.98.98A10.432 10.432 0 0112 17a9.77 9.77 0 01-8.82-5.5 9.9 9.9 0 012.93-3.53z"
                data-name="Path 3653"
            ></path>
        </svg>
    );
}

export default IconHide;