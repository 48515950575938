import { useTheme } from '@mui/material/styles';

function IconWarning(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "19"}
            height={_props.height ? _props.height : "17"}
            viewBox="0 0 19.063 17.007"
        >
            <path
                fill={_props.color ? _props.color : theme.palette.primary.main}
                d="M9.531 1.997l7.53 13.01H2.001l7.53-13.01m-9.26 12.01a2 2 0 001.73 3h15.06a2 2 0 001.73-3L11.261.997a2 2 0 00-3.46 0zm8.26-7v2a1 1 0 002 0v-2a1 1 0 00-2 0zm0 5h2v2h-2z"
            ></path>
        </svg>
    );
}

export default IconWarning;