import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface ImportDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  overrideOption: string;
  onOverrideChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImportDialog: React.FC<ImportDialogProps> = ({ open, onClose, overrideOption, onOverrideChange }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Import'}</DialogTitle>

      <DialogContent>
        <FormControl>
          <FormLabel id="import-options">Choose how to import the uploaded data.</FormLabel>
          <RadioGroup
            aria-labelledby="import-options"
            defaultValue="override"
            value={overrideOption}
            onChange={onOverrideChange}
            name="import-options-group"
          >
            <FormControlLabel value="override" control={<Radio />} label="Override" />
            <FormControlLabel value="merge" control={<Radio />} label="Merge" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>{'Cancel'}</Button>
        <Button onClick={() => onClose(true)} variant="contained" autoFocus>
          {'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;
