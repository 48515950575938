import { useState, useEffect } from 'react';
import FormDataGrid from 'component/molecules/Formrenderer/FormDataGrid';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { IFormInfo } from '@ccs-dip/common/types/formio-types';
import CustomFormBuilder from 'component/molecules/FormBuilder/CustomFormBuilder';

interface IFormBuilderState {
  isEditingForm: boolean;
  isNewForm: boolean;
  isWizardEditing: boolean;
  isNewWizard: boolean;
}

export default function FormBuilderPage() {
  const createFormBuilderState = (): IFormBuilderState => ({
    isEditingForm: false,
    isNewForm: false,
    isWizardEditing: false,
    isNewWizard: false
  });

  const [row, setRow] = useState<IFormInfo | {}>({});
  const [keyGen, setKeyGen] = useState<number>(0);
  const [formBuilderState, setFormBuilderState] = useState<IFormBuilderState>(createFormBuilderState());

  const navigate = useNavigate();

  const handleRowClick = (params: GridRowParams<IFormInfo>): (() => void) => {
    setRow(params.row);
    setFormBuilderState((prevState) => ({
      ...prevState,
      isEditingForm: params.row.display === 'form',
      isWizardEditing: params.row.display !== 'form'
    }));
    setKeyGen((prevKey) => prevKey + 1);

    // Return an empty function as the GridEventListener<'rowClick'>
    return () => {};
  };

  const handleClose = (): void => {
    setFormBuilderState(createFormBuilderState());
  };

  const checkIfFormIsOpen = (): boolean => {
    const { isWizardEditing, isNewWizard, isEditingForm, isNewForm } = formBuilderState;
    return isWizardEditing || isNewWizard || isEditingForm || isNewForm;
  };

  const handleNewForm = (view: 'newForm' | 'newWizard'): void => {
    setRow({});
    setFormBuilderState(() => ({
      ...createFormBuilderState(),
      [view === 'newForm' ? 'isNewForm' : 'isNewWizard']: true
    }));
  };

  useEffect(() => {
    navigate('../settings/3', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {checkIfFormIsOpen() ? (
        <CustomFormBuilder
          key={keyGen}
          formBuilderState={formBuilderState}
          handleClose={handleClose}
          row={row as IFormInfo} // Type assertion since row can be empty initially
        />
      ) : (
        <FormDataGrid
          handleNewForm={(view) => handleNewForm(view as 'newForm' | 'newWizard')}
          handleRowClick={handleRowClick}
        />
      )}
    </>
  );
}
