import static_en from 'config/lang/en_language.json';
import static_nl from 'config/lang/nl_language.json';
import Server from 'entities/RCIPServerProxy';
import { handleError } from 'entities/RCIPErrorHandler';
import { ITranslation } from './ITranslation';
import { LanguageTranslation } from 'component/pages/Translation/ITranslationDataApi';

type LanguageCode = (typeof TranslationService.languages)[number];

type NamespaceCode = (typeof TranslationService.namespaces)[number];

//==============================================================
// private variables
//==============================================================

const getStaticTranslation = (lang: LanguageCode): Promise<ITranslation> => {
  const value = lang === 'en' ? static_en : lang === 'nl' ? static_nl : {};
  return Promise.resolve(value);
};

const getCustomTranslation = (lang: LanguageCode): Promise<ITranslation> => {
  return Server.get(`/language/${lang}`);
};

const removeEmptyKeys = (translation: ITranslation) => {
  Object.keys(translation).forEach((key) => !key && delete translation[key]);
};

//==============================================================
// Translation EntityService
//==============================================================

class TranslationService {
  static languages = ['nl', 'en'] as const;
  static namespaces = ['custom', 'default'] as const;

  delete(lang: LanguageCode, key: string): Promise<void> {
    return Server.delete(`/language/${lang}/${key}`);
  }

  get(lang: LanguageCode, ns: NamespaceCode) {
    try {
      // prettier-ignore
      // default => formio + static
      // custom  => custom
      return Promise.all([
        ns !== 'custom' ? getStaticTranslation(lang) : Promise.resolve({}),
        ns !== 'default' ? getCustomTranslation(lang) : Promise.resolve({})
      ]).then((data:ITranslation[]) => {
        const translation = { ...data[0], ...data[1] };
        removeEmptyKeys(translation);
        return translation as ITranslation;
      });
    } catch (error) {
      throw handleError(error);
    }
  }
  download(lang: LanguageCode) {
    try {
      // prettier-ignore
      // default => formio + static
      // custom  => custom
      return Promise.all([
        getStaticTranslation(lang),
         getCustomTranslation(lang)
      ]).then((data:ITranslation[]) => {
        const translation = { ...data[0], ...data[1] };
        removeEmptyKeys(translation);
        const translationData:LanguageTranslation={data:{
          language:lang,
          translation
        }};
        return translationData as LanguageTranslation;
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  save(lang: LanguageCode, key: string, value: string | object,importOption:string='override'): Promise<void> {
    return Server.post(`/language/${lang}/${key}`, { value,importOption });
  }
}

//==============================================================
// exports
//==============================================================

export type { LanguageCode, NamespaceCode };
export default TranslationService;
