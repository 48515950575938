import { FieldInfo } from '@ccs-dip/common/FieldInfo';
import Server from 'entities/RCIPServerProxy';
import SwaggerFileInfo, { SwaggerInfo } from '@ccs-dip/common/SwaggerFileInfo';

//==============================================================
// OpenApiService
//==============================================================

export class OpenApiService {
  search(fieldName: string): Promise<FieldInfo | undefined> {
    return Server.get(`open-api/search/${fieldName}`).then((data: FieldInfo) => {
      return data;
    });
  }

  getAllSwaggers(): Promise<SwaggerInfo[] | undefined> {
    return Server.get(`open-api/`).then((data: SwaggerInfo[]) => {
      return data;
    });
  }

  deleteSwagger(fileName: string): Promise<SwaggerInfo[] | undefined> {
    return Server.delete(`open-api/${fileName}`).then((data: SwaggerInfo[]) => {
      return data;
    });
  }

  getSwaggerDetails(fileName: string): Promise<SwaggerFileInfo> {
    return Server.get(`open-api/details/${fileName}`).then((data: SwaggerFileInfo) => {
      return data;
    });
  }

  postSwagger(fileName: string, value: SwaggerFileInfo, action: string): Promise<SwaggerInfo[] | undefined> {
    return Server.post(`open-api/${action}/${fileName}`, value).then((data: SwaggerInfo[]) => {
      return data;
    });
  }
}
