export default class RCIPError extends Error {
  type: string;
  code: string;
  transactionId: string;

  constructor(cause: any, message: string, type: string = '', code: string = '', transactionId: string = '') {
    super(message, { cause });
    this.message = message;
    this.type = type;
    this.code = code;
    this.transactionId = transactionId;
  }
}
