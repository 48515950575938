import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';

const SwaggerDataGridIcons = {
  TextSnippetIcon,
  DeleteOutlineIcon,
  FileDownloadIcon,
  StyleOutlinedIcon,
  FolderSharedOutlinedIcon
};
export default SwaggerDataGridIcons;
