import { styled, Theme } from '@mui/material';
export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '9px 15px 0px 15px',
  color: theme.palette?.['secondary']?.main,
  fontWeight: 'bold',
  fontFamily: 'Open Sans',
  background: '#fff',
  fontSize: 10,
  textTransform: 'uppercase',
  zIndex: 100
}));

export const GroupItems = styled('ul')(({ theme }) => ({
  padding: 0,
  background: '#fff',
  fontSize: 14,
  color: theme.palette?.['primary']?.main,
  paddingBottom: 0.3
}));
export const MenuItemsIcon = styled('span')(({ theme }) => ({
  padding: 0,
  background: '#fff',
  fontSize: 14,
  color: theme.palette?.['secondary']?.main
}));

export const formControlWrapper = { width: '100%' };
export const autocompleteWrapper = {
  width: '100%',
  fontFamily: 'Open Sans',
  mr: 2,
  '.MuiFormControl-root .MuiOutlinedInput-root': { p: '10px' }
};
export const noOptions={
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1.2,
  fontSize: '16px',
  fontWeight: 'bold',
  background:'#fff',
  m:-1.7,
  color:(theme:Theme)=>theme.palette.primary.main
}
export const searchResults={
  '&.Mui-disabled': {
backgroundColor: '#fff',
color:(theme:Theme)=>theme.palette.primary.main
},
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  px: 2,
  py:2,
  fontSize: '16px',
  fontWeight: 'bold',
  background:'#fff',
  margin:'-4px 0 -4px 0',
  my:-1,
  color:(theme:Theme)=>theme.palette.primary.main
}
export const personIconStyle = { mr: 2, background: 'transparent' };
export const partyName = { mr: 0.5, fontWeight: 'bold' };
export const noResults = {
  fontWeight: 'bold',
  fontSize: '14px',
  display: 'flex',
  color: (theme: any) => theme.palette?.['primary']?.main
};
export const partyNumber = { mr: 2 };
export const partyDate = { mr: 2 };
export const dividerHeight = { h: 1 };
