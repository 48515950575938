import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowParams,
  GridValidRowModel
} from '@mui/x-data-grid-pro';
import { DataGridContainer } from './EntityListViewStyles';
import { useDispatch } from 'react-redux';
import { setBriefView, setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import PartyBriefView from 'component/pages/Party/PartyBriefView';
import OfferBriefView from 'component/pages/Offer/OfferBriefView';
import PolicyBriefView from 'component/pages/Policy/PolicyBriefView';
import { ClaimBriefView } from 'component/pages/Claim/ClaimBriefView';
import ArchiveBriefView from 'component/pages/Archive/ArchiveBriefView';
import { addActionColumn, actionsColumnExits } from './ActionsColumnExtender';
import { canPerformOperation } from 'api/OperationSupport';
import BillingBriefView from 'component/pages/Billing/BillingBriefView';
import CustomDataGridToolbar, { DataGridProSx } from 'utils/CustomDataGridToolbar';

interface IProps<T extends GridValidRowModel> {
  id?: string;
  columns: GridColDef[];
  rows: T[];
  getId: (row: T) => string;
  getActions?: (params: GridRowParams<T>) => JSX.Element[];
  columnVisibilityModel?: GridColumnVisibilityModel;
  briefViewType?: string;
}

const EntityListView = <T extends GridValidRowModel>(props: IProps<T>) => {
  const dispatch = useDispatch();

  const selectBriefView = (inputNumber: string, briefViewType?: string) => {
    let selectedRow: T | undefined = undefined;
    let billingActivityDesc: string = '';
    let policyVersion: string = '';

    if (briefViewType === 'BillingBriefView') {
      if (rows) {
        selectedRow = rows.find((row) => getId(row) === inputNumber);
      }
      if (!selectedRow) {
        console.error('Selected row not found');
        return;
      }
      billingActivityDesc = selectedRow['BillingInfo']['BillingActivityInfo']['BillingActivityDesc'];
    }

    if (briefViewType === 'PolicyBriefView') {
      if (rows) {
        selectedRow = rows.find((row) => getId(row) === inputNumber);
      }
      if (!selectedRow) {
        console.error('Selected row not found');
        return;
      }
      policyVersion = selectedRow['PolicyVersion'];
    }

    switch (briefViewType) {
      case 'PartyBriefView':
        dispatch(setBriefView(<PartyBriefView partyNumber={String(inputNumber)} />));
        break;
      case 'OfferBriefView':
        dispatch(setBriefView(<OfferBriefView policyNumber={String(inputNumber)} />));
        break;
      case 'PolicyBriefView':
        dispatch(
          setBriefView(<PolicyBriefView policyNumber={String(inputNumber)} policyVersion={String(policyVersion)} />)
        );
        break;
      case 'ClaimBriefView':
        dispatch(setBriefView(<ClaimBriefView claimNumber={String(inputNumber)} />));
        break;
      case 'ArchiveBriefView':
        dispatch(setBriefView(<ArchiveBriefView archiveNumber={String(inputNumber)} />));
        break;
      case 'BillingBriefView':
        dispatch(
          setBriefView(
            <BillingBriefView billingNumber={String(inputNumber)} billingActivityDesc={String(billingActivityDesc)} />
          )
        );
        break;
      default:
        dispatch(setBriefView(<PartyBriefView partyNumber={String(inputNumber)} />));
        break;
    }
    dispatch(setOpenBriefView(true));
  };

  const { columns, rows, getId, columnVisibilityModel = {}, getActions, id, briefViewType } = props;

  const editActionHandler = (id: string) => {
    selectBriefView(id, briefViewType);
  };

  const onRowDoubleClick = (params: GridRowParams<T>) => {
    const id = params.id.toString();
    if (canPerformOperation('edit')) {
      editActionHandler(id);
    } else if (canPerformOperation('view')) {
      editActionHandler(id);
    }
  };

  if (!actionsColumnExits(columns)) {
    addActionColumn(columns, editActionHandler, getActions);
  }

  return (
    <DataGridContainer id={id}>
      <DataGridPro
        sx={DataGridProSx}
        columns={columns}
        rows={rows ?? []}
        columnHeaderHeight={36}
        getRowId={getId}
        onRowDoubleClick={onRowDoubleClick}
        disableColumnPinning
        pagination
        initialState={{
          columns: { columnVisibilityModel },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[
          10,
          20,
          40
        ]}
        slots={{ toolbar: CustomDataGridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            title: id
          }
        }}
      />
    </DataGridContainer>
  );
};

export default EntityListView;
