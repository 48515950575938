export const backdrop = { bgcolor: (theme: any) => theme.palette.secondary.main, width: 70, height: 70 };
export const avatar = { bgcolor: (theme: any) => theme.palette.secondary.main, width: 70, height: 70 };
export const leadInsurerWrapper = {
  width: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const leadInsurerName = {
  fontSize: '24px',
  color: (theme: any) => theme.palette.primary.main,
  fontWeight: 'bold',
  width: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export const leadInsureText = {
  fontSize: '24px',
  color: (theme: any) => theme.palette.primary.main,
  fontWeight: 'bold'
};
export const policyNumber = { fontWeight: 800, fontSize: '14px', color: (theme: any) => theme.palette.primary.main };
export const contentWrap = { mt: 1, mb: 3, height: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'hidden' };
export const insureDetailsText = {
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};
export const coverageDetailsText = {
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};

export const paymentDetailsText = {
  fontWeight: 800,
  fontSize: '16px',
  color: (theme: any) => theme.palette.primary.main
};

export const subtitleText = { color: (theme: any) => theme.palette.primary.main, ml: 2 };
export const editText = { color: (theme: any) => theme.palette.primary.main, ml: 2 };
