import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {useEffect, useState } from 'react';
import FormioReportStyles from './FormioReportStyles';
import FormioReportIcons from './FormioReportIcons';
import { IFormioReportMessage } from './IFormioReport';
import { IMessage } from '@ccs-dip/common/types/message';

const FormOverviewGrid: React.FC<IFormioReportMessage> = ({maximize,messages,formVersion}) => {

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const { t } = useTranslation();
  

  useEffect(() => {
    const savedPagination = localStorage.getItem('dataGridPagination');
    if (savedPagination) {
      setPaginationModel(JSON.parse(savedPagination));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('dataGridPagination', JSON.stringify(paginationModel));
  }, [paginationModel]);

  if(!maximize){return null}
  
  const renderRowCell = (params: any) => (
    <>
      <FormioReportIcons.EditNoteOutlinedIcon sx={FormioReportStyles.iconStyle} />
        {params.row.ref}
    </>
  );

  const renderTypeCell = (params: any) => (
    <>
      <Box sx={FormioReportStyles.headerContent}>
          {FormioReportStyles.setBackgroundColor(params.row.type).icon}
          {FormioReportStyles.setBackgroundColor(params.row.type).text}
      </Box>
    </>
  );
  const renderLabelCell = (params: any) => (
    <>
      <Box sx={FormioReportStyles.headerContent}>
      <FormioReportIcons.BookmarkBorderOutlinedIcon sx={FormioReportStyles.iconStyle} />
      {params.row.label}
      </Box>
    </>
  );

  const renderValueCell = (params: any) => (
    <>
     <Box sx={FormioReportStyles.headerContent}>
          <FormioReportIcons.ChatBubbleOutlineOutlinedIcon sx={FormioReportStyles.iconStyle} fontSize='small' />
          {params.row.value}
    </Box>
    </>
  );


  const columns: GridColDef[] = [
    {
      field: 'ref',
      headerName: t('ref'),
      flex: 1,
      renderCell: renderRowCell
    },
    {
      field: 'label',
      headerName: t('name'),
      flex: 1,
      sortable: true,
      renderCell: renderLabelCell
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      sortable: true,
      renderCell: renderTypeCell
    },
  
    {
      field: 'value',
      headerName: t('value'),
      flex: 1,
      sortable: true,
      renderCell: renderValueCell
    }
  ];

  return (
    <Box sx={{ py: 2 }}>
      <DataGridPro
      key={formVersion}
        sx={{ height: 400 }}
        rows={messages}
        columns={columns}
        editMode='row'
        getRowId={(row: IMessage) => row.ref||''}
        disableColumnPinning
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'ref', sort: 'asc' }]
          },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[
          5,
          10,
          20
        ]}
      />

    </Box>
  );
};

export default FormOverviewGrid;
