import { useTheme } from '@mui/material/styles';
function IconHours(_props: any) {
  const theme = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 32 32'>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.secondary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M18.093 25.441a9.725 9.725 0 004.419-16.664'
        data-name='Path 3632'></path>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.secondary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6.253 15.346q-.018.289-.019.581a9.722 9.722 0 009.691 9.75'
        data-name='Path 3633'></path>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.secondary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M20.902 7.584a9.726 9.726 0 00-14.313 5.757'
        data-name='Path 3634'></path>
      <path fill='none' d='M0 0h32v32H0z' data-name='Rectangle 2383'></path>
      <path
        fill='none'
        stroke={_props.color ? _props.color : theme.palette.secondary.main}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M15.955 10.442v6.092l2.487 3.182'
        data-name='Path 3635'></path>
    </svg>
  );
}

export default IconHours;
